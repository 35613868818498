import { apiConnector } from "../apiConnector";
import { eventEndpoints } from "../apis";


const {
  CREATE_EVENT_API,
  GET_ALL_EVENTS_API,
  GET_EVENT_BY_ID_API,
  UPDATE_EVENT_API,
  DELETE_EVENT_API,
} = eventEndpoints;

// Function to create an event (Admin only)
export const createEvent = async (eventData) => {
  try {
    const response = await apiConnector('POST', CREATE_EVENT_API, eventData);
    
   
    
    return response.data;
  } catch (error) {
    console.error("Error creating event:", error.response?.data || error.message);
    throw new Error(error.response?.data?.message || 'Error creating event');
  }
};

// Function to get all events
export const getAllEvents = async () => {
  try {
    const response = await apiConnector('GET', GET_ALL_EVENTS_API);
    return response.data;
  } catch (error) {
    console.error("Error fetching events:", error.response?.data || error.message);
    throw new Error(error.response?.data?.message || 'Error fetching events');
  }
};

// Function to get a single event by ID
export const getSingleEvent = async (eventId) => {
  try {
    const response = await apiConnector('GET', `${GET_EVENT_BY_ID_API}/${eventId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching event:", error.response?.data || error.message);
    throw new Error(error.response?.data?.message || 'Error fetching event');
  }
};

// Function to update an event (Admin only)
export const updateEvent = async (eventId, eventData) => {
  try {
    const response = await apiConnector('PUT', `${UPDATE_EVENT_API}/${eventId}`, eventData);
    return response.data;
  } catch (error) {
    console.error("Error updating event:", error.response?.data || error.message);
    throw new Error(error.response?.data?.message || 'Error updating event');
  }
};

// Function to delete an event (Admin only)
export const deleteEvent = async (eventId) => {
  try {
    const response = await apiConnector('DELETE', `${DELETE_EVENT_API}/${eventId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting event:", error.response?.data || error.message);
    throw new Error(error.response?.data?.message || 'Error deleting event');
  }
};
