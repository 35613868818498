import { apiConnector } from "../apiConnector";
import { blogEndpoints } from "../apis";

const {
  CREATE_BLOG_API,
  GET_ALL_BLOGS_API,
  GET_SINGLE_BLOG_API,
  UPDATE_BLOG_API,
  DELETE_BLOG_API,
  GET_LATEST_BLOGS_API,
  GET_TRENDING_BLOGS_API,
  INCREMENT_BLOG_VIEWS_API,
} = blogEndpoints;

// Function to create a blog (Admin only)
export const createBlog = async (blogData) => {
  try {
    const response = await apiConnector('POST', CREATE_BLOG_API, blogData);
    return response.data;
  } catch (error) {
    console.error("Error creating blog:", error.response?.data || error.message);
    throw new Error(error.response?.data?.message || 'Error creating blog');
  }
};

// Function to get all blogs
export const getAllBlogs = async () => {
  try {
    const response = await apiConnector('GET', GET_ALL_BLOGS_API);
    return response.data;
  } catch (error) {
    console.error("Error fetching blogs:", error.response?.data || error.message);
    throw new Error(error.response?.data?.message || 'Error fetching blogs');
  }
};

// Function to get the latest blogs
export const getLatestBlogs = async () => {
  try {
    const response = await apiConnector('GET', GET_LATEST_BLOGS_API);
    return response.data;
  } catch (error) {
    console.error("Error fetching latest blogs:", error.response?.data || error.message);
    throw new Error(error.response?.data?.message || 'Error fetching latest blogs');
  }
};

// Function to get trending blogs
export const getTrendingBlogs = async () => {
  try {
    const response = await apiConnector('GET', GET_TRENDING_BLOGS_API);
    return response.data;
  } catch (error) {
    console.error("Error fetching trending blogs:", error.response?.data || error.message);
    throw new Error(error.response?.data?.message || 'Error fetching trending blogs');
  }
};

// Function to get a single blog by ID
export const getSingleBlog = async (blogId) => {
  try {
    const response = await apiConnector('GET', `${GET_SINGLE_BLOG_API}/${blogId}`);
    return response.data;
  } catch (error) {
    console.error("Error fetching blog:", error.response?.data || error.message);
    throw new Error(error.response?.data?.message || 'Error fetching blog');
  }
};

// Function to update a blog (Admin only)
export const updateBlog = async (blogId, blogData) => {
  try {
    const response = await apiConnector('PUT', `${UPDATE_BLOG_API}/${blogId}`, blogData);
    return response.data;
  } catch (error) {
    console.error("Error updating blog:", error.response?.data || error.message);
    throw new Error(error.response?.data?.message || 'Error updating blog');
  }
};

// Function to delete a blog (Admin only)
export const deleteBlog = async (blogId) => {
  try {
    const response = await apiConnector('DELETE', `${DELETE_BLOG_API}/${blogId}`);
    return response.data;
  } catch (error) {
    console.error("Error deleting blog:", error.response?.data || error.message);
    throw new Error(error.response?.data?.message || 'Error deleting blog');
  }
};

// Function to increment the view count of a blog
export const incrementBlogViews = async (blogId) => {
  try {
    const response = await apiConnector('POST', `${INCREMENT_BLOG_VIEWS_API}/${blogId}`);
    return response.data;
  } catch (error) {
    console.error("Error incrementing blog views:", error.response?.data || error.message);
    throw new Error(error.response?.data?.message || 'Error incrementing blog views');
  }
};
