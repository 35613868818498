import React from "react";
import Footer from "../components/common/Footer";

const PrivacyandPolicy = () => {
  return (
    <div className="min-h-screen flex flex-col">
      <div className="flex-grow m-7 p-6 bg-gray-100">
        <div className="max-w-4xl mx-auto bg-white shadow-lg p-8 md:p-12 rounded-lg">
          <h1 className="text-4xl font-extrabold mb-4 text-[#CD1412]">
            Privacy Policy
          </h1>

          <p className="text-lg leading-relaxed text-gray-700 mb-6">
            At IDC India, we are committed to protecting your privacy. This
            Privacy Policy outlines how we collect, use, and safeguard your
            personal information. We collect data such as your name, email, and
            payment details to provide our services, improve your experience,
            and communicate with you.
          </p>

          <p className="text-lg leading-relaxed text-gray-700 mb-6">
            We do not share your personal information with third parties except
            as necessary to deliver our services or comply with legal
            obligations. You have the right to access, update, or delete your
            personal information at any time.
          </p>

          <p className="text-lg leading-relaxed text-gray-700">
            For more details, please read our full Privacy Policy.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default PrivacyandPolicy;
