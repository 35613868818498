import { useEffect, useState } from "react"
import { VscAdd } from "react-icons/vsc"
import { useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { getAllCourses } from "../../../../services/operations/courseDetailsAPI"
import IconBtn from "../../../common/IconBtn"
import CoursesTable from "./admincourseTable"


const AdminCourses = () => {
  
    const { token } = useSelector((state) => state.auth)
    const navigate = useNavigate()
    const [courses, setCourses] = useState([])
  
    useEffect(() => {
      const fetchCourses = async () => {
        const result = await getAllCourses(token)
        if (result) {
          setCourses(result)
        }
      }
      fetchCourses()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  
    return (
      <div>
        <div className="mb-14 flex items-center justify-between">
          <h1 className="text-3xl font-semibold text-[#CD1412] ">Courses</h1>
          <IconBtn
            text="Add Course"
            onclick={() => navigate("/dashboard/add-courses")}
          >
            <VscAdd />
          </IconBtn>
        </div>
       
        {courses && <CoursesTable courses={courses} setCourses={setCourses} />}
      </div>
  )
}

export default AdminCourses