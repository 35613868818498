import React, { useEffect, useRef, useState } from 'react'
import INstructor from "../../../assets/Images/Instructor.jpg";
import HighlightText from './HighlightText';
import CTAButton from "./Button";
import { FaArrowRight } from "react-icons/fa";

const InstructionSec = () => {
    const [inView, setInView] = useState(false);
    const ref = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(
        ([entry]) => setInView(entry.isIntersecting),
        { threshold: 0.5 }
      );
  
      if (ref.current) {
        observer.observe(ref.current);
      }
  
      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, []);



    return (
        <div className='mt-16'>
            <div className='flex lg:flex-row  flex-col lg:gap-20 gap-10 items-center rounded-xl'>
                <div 
                    className='lg:w-[50%] 
                    shadow-[-10px_-10px_0px_3px_#CD1412] rounded-xl lg:mb-10'>
                    <img src={INstructor} alt="INstructor"
                        className='shadow-[#CD1412] rounded-xl w-full h-full'
                    />
                </div>

                <div className='lg:w-[50%]  flex-col flex  lg:gap-10 gap-10 ml-8 lg:ml-0'>
                    <div className='text-4xl font-semibold w-[50%]'>
                        Become an
                        <HighlightText text={"instructor"} />
                    </div>


                    <div  >
                        <p className='font-medium text-[16px] w-[80%] text-richblack-300 '>
                            Instructors from around the world teach millions of students on IDC India. We provide the tools and skills to teach what you love.
                        </p>
                    </div>

                    <div className='w-fit'>
                        <CTAButton active={true} linkto={"contact"}>
                            <div className='flex flex-row items-center gap-2'>
                                Start Teaching Today
                                <FaArrowRight />
                            </div>
                        </CTAButton>
                    </div>





                </div>

            </div>
        </div>
    )
}

export default InstructionSec