import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { getSingleEvent } from '../services/operations/evnts'; 

const EventDetail = () => {
    const { eventId } = useParams();
    const [event, setEvent] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [currentIndex, setCurrentIndex] = useState(0);

    useEffect(() => {
      const fetchEventDetails = async () => {
        try {
          const data = await getSingleEvent(eventId);
          
          if (data && data.event) {
            setEvent(data.event);
          } else {
            setEvent(null);
          }
        } catch (error) {
          setError(error.message);
        } finally {
          setLoading(false);
        }
      };
  
      if (eventId) {
        fetchEventDetails();
      } else {
        setError("Event ID is missing.");
        setLoading(false);
      }
    }, [eventId]);

    useEffect(() => {
      const interval = setInterval(() => {
        setCurrentIndex((prevIndex) =>
          prevIndex === event.imageUrls.length - 1 ? 0 : prevIndex + 1
        );
      }, 3000); // Change slide every 3 seconds

      return () => clearInterval(interval); // Clean up the interval on component unmount
    }, [event]);

    const handlePrev = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === 0 ? event.imageUrls.length - 1 : prevIndex - 1
      );
    };

    const handleNext = () => {
      setCurrentIndex((prevIndex) =>
        prevIndex === event.imageUrls.length - 1 ? 0 : prevIndex + 1
      );
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error: {error}</p>;
  
    if (!event) return <p>Event not found</p>;
  
    return (
      <div className="p-4 flex flex-col items-center justify-center mt-5 mb-4">
      <div className='flex items-center justify-center'>
        <h3 className='w-full bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] bg-clip-text text-3xl font-semibold text-transparent'>Join Us for a Range of Engaging and Enriching Experiences</h3>
      </div>
        <div className="w-full max-w-[90%] bg-richblack-600 mt-10 text-white dark:bg-white dark:text-richblack-700 shadow-lg rounded-lg overflow-hidden">
          <div className="relative lg:h-[30rem]">
            {event.imageUrls.length > 0 && (
              <>
                <img
                  src={event.imageUrls[currentIndex]}
                  alt={`Event image ${currentIndex + 1}`}
                  className="w-full h-[30rem] object-cover"
                />
                <button
                  onClick={handlePrev}
                  className="absolute top-1/2 left-4 transform -translate-y-1/2 text-white bg-blue-200 p-2 rounded-full"
                >
                  &#9664;
                </button>
                <button
                  onClick={handleNext}
                  className="absolute top-1/2 right-4 transform -translate-y-1/2 text-white bg-blue-200 p-2 rounded-full"
                >
                  &#9654;
                </button>
              </>
            )}
          </div>
          <div className="flex flex-row justify-between mt-10 w-full px-4">
              <p className="text-[#f89694] dark:text-richblack-700 text-sm">
                {`Start Date: ${new Date(event.startDate).toLocaleDateString()}`}
              </p>
              <p className="text-[#f89694] dark:text-richblack-700 text-sm">
                {`End Date: ${new Date(event.endDate).toLocaleDateString()}`}
              </p>
            </div>
          <div className="p-4 flex flex-col justify-center items-center">
            <h1 className="text-3xl font-bold text-white dark:text-richblack-700">
              {event.heading}
            </h1>
            <p className="text-richblack-50 dark:text-richblack-700 mt-4">
              {event.description}
            </p>
            
          </div>
        </div>
      </div>
    );
  };

export default EventDetail;
