import React from "react";
import Footer from "../components/common/Footer";

const CookiePolicy = () => {
  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
    <div className="flex-grow flex flex-col items-center justify-center p-6">
      <div className="bg-white shadow-2xl rounded-xl max-w-4xl w-full p-8 md:p-12">
          <h1 className="text-4xl font-extrabold mb-4 text-[#CD1412]">
            Cookie Policy
          </h1>

          <p className="text-lg leading-relaxed text-gray-700 mb-6">
            This Cookie Policy explains how IDC India uses cookies to enhance
            your experience on our website. Cookies are small text files stored
            on your device that help us recognize you and remember your
            preferences. We use cookies to analyze traffic, improve website
            functionality, and deliver targeted advertisements. By using our
            website, you agree to our use of cookies.
          </p>

          <p className="text-lg leading-relaxed text-gray-700 mb-6">
            You may control or disable cookies through your browser settings;
            however, disabling cookies may affect your user experience. For more
            information, please review our detailed Cookie Policy.
          </p>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default CookiePolicy;
