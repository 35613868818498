import React, { useEffect, useState } from 'react';
import { transactionEndpoints } from '../../../../services/apis';
import { apiConnector } from '../../../../services/apiConnector';
import { useSelector } from 'react-redux';

const getStatusStyle = (status) => {
    switch (status) {
      case 'failed':
        return { color: '#CD1412' }; // Hex color
      case 'success':
        return { color: '#06D6A0' }; // Custom color
      case 'pending':
        return { color: '#47A5C5' }; // Custom color
      default:
        return {};
    }
  };
const TransactionTable = () => {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const { token } = useSelector((state) => state.auth);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await apiConnector('GET', transactionEndpoints.GET_TRANSACTION_DETAILS_API, null, {}, null);
        if (response.data.success) {
          setTransactions(response.data.data);
        }
      } catch (error) {
        console.error('Error fetching transactions:', error);
      }
      setLoading(false);
    };

    fetchTransactions();
  }, [token]);

  return (
    <div className="overflow-x-auto">
      {loading ? (
        <div className="flex items-center justify-center p-4">
          <div className="w-6 h-6 border-4 border-t-4 border-gray-200 border-t-blue-500 rounded-full animate-spin"></div>
        </div>
      ) : (
        <table className="min-w-full divide-y divide-gray-300 bg-gray-100">
          <thead className="bg-gray-200">
            <tr>
              <th className="px-6 py-3 text-left text-xs font-medium text-richblack-5 bg-richblack-800 uppercase tracking-wider">Username</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-richblack-5 bg-richblack-800 uppercase tracking-wider">Course Name</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-richblack-5 bg-richblack-800 uppercase tracking-wider">Payment ID</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-richblack-5 bg-richblack-800 uppercase tracking-wider">Order ID</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-richblack-5 bg-richblack-800 uppercase tracking-wider">Amount</th>
              <th className="px-6 py-3 text-left text-xs font-medium text-richblack-5 bg-richblack-800 uppercase tracking-wider">Status</th>
            </tr>
          </thead>
          <tbody className="bg-richblack-100 text-white divide-y divide-gray-300">
            {transactions.map(transaction => (
              <tr key={transaction.orderId} className="hover:bg-gray-200 transition-colors duration-200">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-richblack-800">{transaction.username}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-richblack-800">{transaction.courses}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-richblack-800">{transaction.paymentId}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-richblack-800">{transaction.orderId}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-richblack-800">Rs. {transaction.amount}</td>
                <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-[#CD1412]" >{transaction.status}
       
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default TransactionTable;
