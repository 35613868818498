// server/operation/category.js
import { toast } from "react-hot-toast";
import { apiConnector } from "../apiConnector";
import { categories } from "../apis";

const {
  CREATE_CATEGORY_API,
  CATEGORIES_API,
  DELETE_CATEGORY_API
} = categories;

export const createCategory = async (data) => {
  const toastId = toast.loading("Creating category...");
  try {
    const response = await apiConnector("POST", CREATE_CATEGORY_API, data);
    if (!response?.data?.success) {
      throw new Error("Could not create category");
    }
    toast.success("Category created successfully");
    return response?.data?.data;
  } catch (error) {
    console.log("Create Category ERROR:", error);
    toast.error(error.message);
    throw error;
  } finally {
    toast.dismiss(toastId);
  }
};

export const showCategories = async () => {
  const toastId = toast.loading("Loading...");
  try {
    const response = await apiConnector("GET", CATEGORIES_API);
    if (!response?.data?.success) {
      throw new Error("Could not fetch categories");
    }
    return response?.data?.data;
  } catch (error) {
    console.log("Show Categories ERROR:", error);
    toast.error(error.message);
    throw error;
  } finally {
    toast.dismiss(toastId);
  }
};


export const deleteCategory = async (id) => {
  const toastId = toast.loading("Deleting category...");
  try {
    const response = await apiConnector("DELETE", `${DELETE_CATEGORY_API}/${id}`);
    // console.log(response, "response")
    if (!response?.data?.success) {
      throw new Error("Could not delete category");
    }
    toast.success("Category deleted successfully");
  } catch (error) {
    if (error.response) {
      console.error("Delete Category ERROR: Response Error", error.response.data);
      toast.error(`Failed to delete category: ${error.response.data.message || 'Unknown error'}`);
    } else if (error.request) {
      console.error("Delete Category ERROR: No response received", error.request);
      toast.error("Failed to delete category: No response from server");
    } else {
      console.error("Delete Category ERROR: Request Error", error.message);
      toast.error(`Failed to delete category: ${error.message}`);
    }
    throw error;
  } finally {
    toast.dismiss(toastId);
  }
};
