import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { addProject } from "../../../../../slices/projectSlice";
import { getInternProjectById } from "../../../../../services/operations/InternData"; // Ensure this path is correct

const ProjectDetails = () => {
  const { id } = useParams(); // Get the project ID from the URL
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null); // State to hold error messages

  useEffect(() => {
    const fetchProject = async () => {
      try {
        const response = await getInternProjectById(id);
        setProject(response.data); // Access the nested project data correctly
      } catch (error) {
        setError(error.message);
        console.error("Failed to fetch project details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProject();
  }, [id]);

  const handleAddProject = () => {
    if (project) {
      // Check if the project already exists in local storage
      const storedProjects = JSON.parse(localStorage.getItem('userProjects')) || [];
      const projectExists = storedProjects.some((proj) => proj._id === project._id);

      if (projectExists) {
        toast.error("Project already exists");
      } else {
        // Save the project to local storage
        storedProjects.push(project);
        localStorage.setItem('userProjects', JSON.stringify(storedProjects));

        // Dispatch the addProject action to Redux store (if necessary)
        dispatch(addProject(project));
        toast.success("Project added successfully");
        navigate("/intern/interndashboard");
      }
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div className="text-red-600">{error}</div>; // Show error message if fetch fails
  }

  if (!project) {
    return <div>Project not found</div>; // Show message if no project is found
  }

  return (
    <div className="min-h-screen p-6">
      <div className="container mx-auto">
        <h2 className="text-2xl font-bold mb-6 text-center text-richblack-800">
          {project.title} {/* Correctly display project title */}
        </h2>
        <img
          src={project.image}  // Correctly display project image
          alt={project.title}
          className="h-[20rem] w-full object-cover rounded mb-4"
        />
        <p className="text-lg mb-4">
          <strong>Description:</strong> {project.description}
        </p>
        <p className="text-lg mb-4">
          <strong>Tech Stack:</strong> {project.tech}
        </p>
        <p className="text-lg mb-4">
          <strong>Topic:</strong> {project.topic}
        </p>

        <div className="mt-10 text-center">
          <button
            onClick={handleAddProject}
            className="bg-[#D22E2C] text-white text-sm px-6 py-2 rounded-lg"
          >
            Add Project
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
