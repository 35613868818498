import img1 from "./img/9.jpg";
import img2 from "./img/3.jpg";
import img3 from "./img/8.jpg";
import img4 from "./img/4.jpg";
import img5 from "./img/7.jpg";
import img6 from "./img/2.jpg";
import img7 from "./img/1.jpg";
import img8 from "./img/5.jpg";
import img10 from "./img/6.jpg";

// data.js
export const teamData = [

      {
        image: img5,
        name: 'Gulshan Yadav ',
        description: 'Mr. Gulshan Kumar is a B.Tech CSE engineer from MDU University, specializing in full-stack development with comprehensive tech skills.',
        courseName: ' Full Stack Developer',
        link: 'https://www.linkedin.com/in/gulshan-yadav-30a980175/',
      },
      {
        image: img7,
        name: 'Arya Chaurasia',
        description: 'Expert Full Stack Developer specializing in JavaScript, React, Node.js, hands-on projects, clear lessons, and real-world applications.',
        courseName: ' Mern Stack Developer',
        link: 'https://www.linkedin.com/in/gulshan-yadav-30a980175/',
      },
      {
        image: img1,
        name: 'Surbhi Jain',
        description: 'Expert Full Stack Developer specializing in JavaScript, React, Node.js, hands-on projects, clear lessons, and real-world applications.',
        courseName: ' Graphic Designer',
        link: 'https://www.linkedin.com/in/gulshan-yadav-30a980175/',
      },
      {
        image: img8,
        name: 'Enamul Haq ',
        description: 'Expert  Digital Marketer skilled in SEO, social media, and content strategy. Passionate about driving growth and brand success.',
        courseName: ' Digital Marketer',
        link: 'https://www.linkedin.com/in/enamul-haq-29a9932a0/',
      },
    {
      image: img10,
      name: 'Mohd Arman ',
      description: 'Expert Full Stack Developer specializing in JavaScript, React, Node.js, hands-on projects, clear lessons, and real-world applications.',
      courseName: ' Full Stack Course',
      link: 'https://www.linkedin.com/in/gulshan-yadav-30a980175/',
    },
 
    {
        image: img3,
        name: 'Awadhesh',
        description: 'Awadhesh excels in AI, specializing in machine learning, deep learning, and data science with extensive industry and teaching experience.',
        courseName: 'Artificial Intelligence',
        link: 'https://www.linkedin.com/in/gulshan-yadav-30a980175/',
      },
      // {
      //   image: img4,
      //   name: 'Sumit',
      //   description: 'Sumit excels in AI, Python, and data science, offering clear, practical lessons with hands-on projects and real-world applications.',
      //   courseName: 'Machine Learning',
      //   link: 'https://www.linkedin.com/in/gulshan-yadav-30a980175/',
      // },
     
      {
        image: img6,
        name: 'Yasar',
        description: 'Yasar is a deep learning expert with years of experience, specializing in neural networks, AI applications, and model optimization.',
        courseName: 'Deep Learning',
        link: 'https://www.linkedin.com/in/gulshan-yadav-30a980175/',
      },
    
  ];
  