import React from "react";
import Footer from "../components/common/Footer";

const RefundPolicy = () => {
  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
    <div className="flex-grow flex flex-col items-center justify-center p-6">
      <div className="bg-white shadow-2xl rounded-xl max-w-4xl w-full p-8 md:p-12">
        <h1 className="text-4xl font-extrabold mb-4 text-[#CD1412]">
          Refund Policy
        </h1>

        <p className="text-lg leading-relaxed text-gray-700 mb-8">
          At IDC India, your satisfaction is our priority. If you are not fully
          satisfied with any course, you may request a refund within 7 days of
          the purchase date. Refunds will only be considered if you have not
          accessed more than 15% of the course content.
        </p>

        <p className="text-lg leading-relaxed text-gray-700 mb-8">
          To initiate a refund, please contact our support team with your
          purchase details. Refunds will be processed within 21 business days.
          Please note that refunds are not applicable for any course purchased
          during a promotional discount period.
        </p>

        <h2 className="text-2xl font-semibold mt-10 mb-6 text-gray-800">
          Contact Us
        </h2>
        <p className="text-lg leading-relaxed text-gray-700 mb-6">
          If you have any questions about our Returns and Refunds Policy, please
          contact us by e-mail at{" "}
          <a
            href="mailto:info@idcindia.net"
            className="text-blue-600 underline hover:text-blue-800"
          >
            info@idcindia.net
          </a>
          .
        </p>
      </div>
    </div>
    <Footer />
  </div>
  );
};

export default RefundPolicy;
