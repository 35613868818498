import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import {
  createEvent,
  updateEvent,
  getAllEvents,
  deleteEvent,
  getSingleEvent,
} from "../../../../services/operations/evnts";
import { toast } from "react-toastify";
import EventTable from "./EventTable";
import ReactQuill from "react-quill";

const EventForm = ({ existingEvent = null, onSubmitSuccess }) => {
  const [formData, setFormData] = useState({
    heading: existingEvent ? existingEvent.heading : "",
    description: existingEvent ? existingEvent.description : "",
    startDate: existingEvent
      ? new Date(existingEvent.startDate).toISOString().slice(0, 10)
      : "",
    endDate: existingEvent
      ? new Date(existingEvent.endDate).toISOString().slice(0, 10)
      : "",
    image: [], // Array for multiple images
    thumbnail: null, // Single thumbnail file
  });

  const [events, setEvents] = useState([]);

  const onDropImages = useCallback((acceptedFiles) => {
    setFormData((prevState) => ({
      ...prevState,
      image: acceptedFiles,
    }));
  }, []);

  const onDropThumbnail = useCallback((acceptedFiles) => {
    setFormData((prevState) => ({
      ...prevState,
      thumbnail: acceptedFiles[0] || null,
    }));
  }, []);

  const {
    getRootProps: getRootPropsImages,
    getInputProps: getInputPropsImages,
    isDragActive: isDragActiveImages,
  } = useDropzone({
    onDrop: onDropImages,
    accept: "image/*",
    multiple: true,
  });

  const {
    getRootProps: getRootPropsThumbnail,
    getInputProps: getInputPropsThumbnail,
    isDragActive: isDragActiveThumbnail,
  } = useDropzone({
    onDrop: onDropThumbnail,
    accept: "image/*",
    multiple: false,
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  
  const handleDescriptionChange = (value) => {
    setFormData((prevState) => ({
      ...prevState,
      description: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate that images and thumbnail are provided
    if (formData.image.length === 0) {
      toast.error("At least one image is required.");
      return;
    }

    if (!formData.thumbnail) {
      toast.error("Thumbnail image is required.");
      return;
    }

    try {
      const data = new FormData();
      data.append("heading", formData.heading);
      data.append("description", formData.description);
      data.append("startDate", formData.startDate);
      data.append("endDate", formData.endDate);

      // Append images
      formData.image.forEach((image) => {
        data.append("image", image);
      });

      // Append thumbnail
      if (formData.thumbnail) {
        data.append("thumbnail", formData.thumbnail);
      }

      if (existingEvent) {
        await updateEvent(existingEvent._id, data);
        toast.success("Event updated successfully");
      } else {
        await createEvent(data);
        toast.success("Event created successfully");
      }

      if (onSubmitSuccess) onSubmitSuccess();

      // Reset form
      setFormData({
        heading: "",
        description: "",
        startDate: "",
        endDate: "",
        image: [],
        thumbnail: null,
      });

      fetchEventData();
    } catch (error) {
      toast.error(error.response?.data?.error || "An error occurred");
    }
  };

  const fetchEventData = async () => {
    try {
      const eventData = await getAllEvents();
      if (Array.isArray(eventData.events)) {
        setEvents(eventData.events);
      } else {
        setEvents([]);
      }
    } catch (error) {
      toast.error("Failed to fetch events");
    }
  };

  useEffect(() => {
    fetchEventData();
  }, []);

  const handleEdit = async (eventId) => {
    try {
      const eventData = await getSingleEvent(eventId);
      setFormData({
        heading: eventData.heading,
        description: eventData.description,
        startDate: new Date(eventData.startDate).toISOString().slice(0, 10),
        endDate: new Date(eventData.endDate).toISOString().slice(0, 10),
        image: [],
        thumbnail: null,
      });
    } catch (error) {
      toast.error("Failed to fetch event details");
    }
  };

  const modules = {
    toolbar: [
        [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'color': [] }, { 'background': [] }], // Color options
        [{ 'font': [] }],
        ['clean'] 
    ],
};


  const handleDelete = async (eventId) => {
    try {
      await deleteEvent(eventId);
      setEvents((prevEvents) =>
        prevEvents.filter((event) => event._id !== eventId)
      );
      toast.success("Event deleted successfully");
    } catch (error) {
      toast.error("Failed to delete event");
    }
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit}
        className="space-y-4 bg-richblack-700 p-8 rounded-xl"
      >
        <div>
          <label className="block text-sm font-medium text-richblack-100">
            Heading
          </label>
          <input
            type="text"
            name="heading"
            value={formData.heading}
            onChange={handleChange}
            className="mt-1 block w-full p-3 border-richblack-100 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            required
          />
        </div>

        {/*  <div>
          <label className="block text-sm font-medium text-richblack-100">Description</label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            rows="4"
            className="mt-1 block w-full p-3 border-richblack-100 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            required
          />  
           </div>*/}

        <div>
          <label className="block text-sm font-medium text-richblack-700 dark:text-richblack-700">
            Description
          </label>
          <ReactQuill
            theme="snow"
            value={formData.description}
            onChange={handleDescriptionChange}
            className="bg-richblack-50 dark:bg-richblack-300 dark:text-white text-richblack-500   rounded-md "
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-richblack-100 mt-10">
            Start Date
          </label>
          <input
            type="date"
            name="startDate"
            value={formData.startDate}
            onChange={handleChange}
            className="mt-1 block w-full p-3 border-richblack-100 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            required
          />
        </div>

        <div>
          <label className="block text-sm font-medium text-richblack-100">
            End Date
          </label>
          <input
            type="date"
            name="endDate"
            value={formData.endDate}
            onChange={handleChange}
            className="mt-1 block w-full p-3 border-richblack-100 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
            required
          />
        </div>

        <div
          {...getRootPropsImages()}
          className="cursor-pointer border-2 border-dashed border-richblack-100 text-white p-4 rounded-md text-center"
        >
          <input {...getInputPropsImages()} />
          {isDragActiveImages ? (
            <p>Drop the files here...</p>
          ) : (
            <p>Drag 'n' drop some images here, or click to select files</p>
          )}
        </div>

        {formData.image.length > 0 && (
          <div className="mt-2">
            {formData.image.map((file, index) => (
              <img
                key={index}
                src={URL.createObjectURL(file)}
                alt={`Selected ${index}`}
                className="w-32 h-32 object-cover"
              />
            ))}
          </div>
        )}

        <div
          {...getRootPropsThumbnail()}
          className="cursor-pointer border-2 border-dashed border-richblack-100 text-white p-4 rounded-md text-center mt-4"
        >
          <input {...getInputPropsThumbnail()} />
          {isDragActiveThumbnail ? (
            <p>Drop the thumbnail here...</p>
          ) : (
            <p>Drag 'n' drop a thumbnail here, or click to select a file</p>
          )}
        </div>

        {formData.thumbnail && (
          <div className="mt-2">
            <img
              src={URL.createObjectURL(formData.thumbnail)}
              alt="thumbnail"
              className="w-32 h-32 object-cover"
            />
          </div>
        )}

        <button
          type="submit"
          className="w-full bg-indigo-600 text-white py-2 px-4 rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        >
          {existingEvent ? "Update Event" : "Create Event"}
        </button>
      </form>

      <EventTable events={events} onEdit={handleEdit} onDelete={handleDelete} />
    </div>
  );
};

export default EventForm;
