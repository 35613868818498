import React from 'react'

const HighlightText = ({text}) => {
  return (
    <span className='font-bold text-[#CD1412] dark:text-[#ff5050] cursor-grab'>
    {" "}
      {text}
    </span>
  )
}

export default HighlightText