// src/components/TeacherSlider.js

import React, { useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { teacherData } from '../../../data/teacherData';
import 'swiper/swiper-bundle.min.css';
import { Link } from 'react-router-dom';

import SwiperCore, { Autoplay, Pagination } from 'swiper';

// Install Swiper modules
SwiperCore.use([Autoplay, Pagination]);

const TeacherSlider = () => {
  const swiperRef = useRef(null);

  const handleMouseEnter = () => {
    if (swiperRef.current && swiperRef.current.autoplay.running) {
      swiperRef.current.autoplay.stop();
    }
  };

  const handleMouseLeave = () => {
    if (swiperRef.current && !swiperRef.current.autoplay.running) {
      swiperRef.current.autoplay.start();
    }
  };

  return (
    <div className="w-screen ">
      <div className='flex justify-center mb-5'>
        <h1 className='lg:ml-[35rem] bg-gradient-to-b from-[#FF512F] to-[#F09819] bg-clip-text text-4xl font-semibold text-transparent lg:w-[70%]'>
        Qualified Expert
        </h1>
      </div>
      <Swiper
        slidesPerView={3}
        spaceBetween={-70} // Gap between images (16px)
        loop={true}
        autoplay={{
          delay: 1000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
          el: '.custom-pagination', // Use a custom class for pagination
        }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 1,
            spaceBetween: 10
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 2,
            spaceBetween: 20
          },
          // when window width is >= 1024px
          1024: {
            slidesPerView: 3,
            spaceBetween: 30
          }
        }}
      >
        {teacherData.map((teacher, index) => (
          <SwiperSlide
            key={index}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            <div className="relative group h-[400px] w-[80%] mx-auto overflow-hidden cursor-pointer rounded-xl">
              <img
                src={teacher.image}
                alt={teacher.name}
                className="w-full h-full object-cover group-hover:scale-105 transition-transform duration-300"
              />
              <div className="absolute inset-0 bg-black bg-opacity-50 group-hover:bg-opacity-20 transition-opacity duration-300"></div>
              <div className="absolute bottom-0 left-0 p-4 text-white h-[180px] hover:bg-opacity-75 hover:bg-richblack-800 transition-opacity duration-300">
                <h3 className="text-xl font-bold text-[#c47888]">{teacher.name}</h3>
                <p>{teacher.description}</p>
                <div className='flex flex-row justify-between'>
                  <span className="block mt-2 bg-richblack-100 h-6 cursor-pointer text-richblack-800 w-[10rem] px-2 rounded-lg hover:bg-[#CD1412] hover:text-white">{teacher.courseName}</span>
                  <div className='bg-blue-100 w-20 rounded-lg hover:bg-[#CD1412] flex items-center justify-center cursor-pointer'>
                    <Link to={teacher.link}><p>LinkedIn</p></Link>
                  </div>
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
      <div className=' mt-10 gap-3' >
        <div className="custom-pagination flex justify-center gap-3"></div>
      </div>
    </div>
  );
};

export default TeacherSlider;
