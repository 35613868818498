import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAllInternProjects } from '../../../../../services/operations/InternData';

const ProjectBoard = () => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [selectedTech, setSelectedTech] = useState('');
  const [selectedTopic, setSelectedTopic] = useState('');
  const [technologies, setTechnologies] = useState([]);
  const [topics, setTopics] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await getAllInternProjects();
        if (response.success && Array.isArray(response.data)) {
          setProjects(response.data);
          setFilteredProjects(response.data);
          
          const techs = [...new Set(response.data.map(project => project.tech))];
          const tops = [...new Set(response.data.map(project => project.topic))];
          setTechnologies(techs);
          setTopics(tops);
        }
      } catch (err) {
        console.error('Error fetching projects:', err);
      }
    };

    fetchProjects();
  }, []);

  const handleFilter = () => {
    const filtered = projects.filter(project =>
      (selectedTech === '' || project.tech === selectedTech) &&
      (selectedTopic === '' || project.topic === selectedTopic)
    );
    setFilteredProjects(filtered);
  };

  const handleShowAll = () => {
    setFilteredProjects(projects);
    setSelectedTech('');
    setSelectedTopic('');
  };

  const handleCardClick = (id) => {
    navigate(`/intern/projectdetails/${id}`);
  };

  return (
    <div className="min-h-screen p-6 bg-richblack-800">
      <div className="container mx-auto">
        <div>
        <h2 className="text-2xl font-bold mb-6 text-center text-richblack-5 dark:text-white">Project Board</h2>
        </div>

        <div className="flex justify-center space-x-4 mb-6">
          <select
            className="p-2 border border-richblack-300 rounded-md"
            value={selectedTech}
            onChange={(e) => setSelectedTech(e.target.value)}
          >
            <option value="">Select Technology</option>
            {technologies.map((tech, index) => (
              <option key={index} value={tech}>{tech}</option>
            ))}
          </select>

          <select
            className="p-2 border border-richblack-300 rounded-md"
            value={selectedTopic}
            onChange={(e) => setSelectedTopic(e.target.value)}
          >
            <option value="">Select Topic</option>
            {topics.map((topic, index) => (
              <option key={index} value={topic}>{topic}</option>
            ))}
          </select>

          <button
            className="p-2 bg-blue-500 text-white rounded-md"
            onClick={handleFilter}
          >
            Apply Filter
          </button>

          <button
            className="p-2 bg-richblack-500 text-white rounded-md"
            onClick={handleShowAll}
          >
            Show All
          </button>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {filteredProjects.length > 0 ? (
            filteredProjects.map((project) => (
              <div
                key={project._id}
                className="bg-white h-[24rem] p-4 rounded-lg shadow-lg cursor-pointer"
                onClick={() => handleCardClick(project._id)}
              >
                <img
                  src={project.image}
                  alt={project.title}
                  className="h-[15rem] w-full object-cover rounded-t-lg mb-4"
                />
                <h3 className="text-xl font-semibold text-richblack-800 mb-2">{project.title}</h3>
                <p className="text-richblack-700 mb-2"><strong>Tech:</strong> {project.tech}</p>
                <p className="text-richblack-700 mb-4"><strong>Topic:</strong> {project.topic}</p>
              </div>
            ))
          ) : (
            <div className="text-center col-span-full">No projects found.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProjectBoard;
