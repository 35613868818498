import React, { useEffect, useState } from 'react';
import { getAllInternProjects } from '../../../../services/operations/InternData'; // Import the function to get all intern projects
import { FaEye, FaEdit, FaTrashAlt } from 'react-icons/fa'; // Icons for view, edit, and delete

const InternProjectsPage = () => {
  const [projects, setProjects] = useState([]); // Initialize as an array
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch intern projects when the component mounts
  useEffect(() => {
    const fetchProjects = async () => {
      try {
        setLoading(true); // Set loading state to true before fetching data
        const response = await getAllInternProjects();

        // Access the 'data' field inside the response object
        if (response.success && Array.isArray(response.data)) {
          setProjects(response.data); // Set the data if it's an array
        } else {
          setProjects([]); // Set an empty array if the response isn't valid
        }

      } catch (err) {
        setError(err.message || 'Something went wrong'); // Capture error message
      } finally {
        setLoading(false); // Always turn off loading state after fetching data
      }
    };

    fetchProjects();
  }, []); // Empty dependency array ensures this runs once after mount

  if (loading) return <div className="text-center">Loading...</div>;
  if (error) return <div className="text-center text-red-500">Error: {error}</div>;
  if (!projects || projects.length === 0) {
    return <div className="text-center">No projects available.</div>;
  }

  return (
    <div className="min-h-screen bg-gray-100 p-4 ">
      <div className="container mx-auto">
        <h2 className="text-2xl font-bold mb-4 text-center text-richblack-800 dark:text-richblack-50">Intern Projects</h2>

        <div className="overflow-x-auto">
          <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
            <thead className="bg-richblack-200">
              <tr className="text-left text-richblack-700">
                <th className="py-3 px-4">S.No</th>
                <th className="py-3 px-4">Title</th>
                <th className="py-3 px-4">Description</th>
                <th className="py-3 px-4">Image</th>
                <th className="py-3 px-4 text-center">Actions</th>
              </tr>
            </thead>
            <tbody>
              {projects.map((intern, index) => (
                <tr key={intern._id} className="border-b bg-richblack-800 dark:bg-white dark:text-richblack-800 text-white">
                  <td className="py-3 px-4">{index + 1}</td>
                  <td className="py-3 px-4">{intern.title}</td>
                  <td className="py-3 px-4">{truncateDescription(intern.description)}</td>
                  <td className="py-3 px-4">
                    <img
                      src={intern.image}
                      alt={intern.title}
                      className="h-12 w-12 object-cover rounded-md cursor-pointer transition-transform duration-300 hover:scale-105"
                      onClick={() => window.open(intern.image, '_blank')} // Opens the image in a new tab
                    />
                  </td>
                  <td className="py-3 px-4 flex justify-center space-x-4">
                    <button
                      className="text-blue-500 hover:text-blue-700 transition-colors"
                      onClick={() => alert('View details of ' + intern.title)}
                    >
                      <FaEye size={18} />
                    </button>
                    <button
                      className="text-green-500 hover:text-green-700 transition-colors"
                      onClick={() => alert('Edit project ' + intern.title)}
                    >
                      <FaEdit size={18} />
                    </button>
                    <button
                      className="text-red-500 hover:text-red-700 transition-colors"
                      onClick={() => alert('Delete project ' + intern.title)}
                    >
                      <FaTrashAlt size={18} />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

// Utility function to truncate the description to a certain word limit
const truncateDescription = (description, wordLimit = 10) => {
  const words = description.split(' ');
  return words.length > wordLimit ? words.slice(0, wordLimit).join(' ') + '...' : description;
};

export default InternProjectsPage;
