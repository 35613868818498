  const BASE_URL = process.env.REACT_APP_BASE_URL

  // AUTH ENDPOINTS
  export const endpoints = {
    SENDOTP_API: BASE_URL + "/auth/sendotp",
    SIGNUP_API: BASE_URL + "/auth/signup",
    LOGIN_API: BASE_URL + "/auth/login",
    RESETPASSTOKEN_API: BASE_URL + "/auth/reset-password-token",
    RESETPASSWORD_API: BASE_URL + "/auth/reset-password",
    CREATE_INSTRUCTOR_ACCOUNT:BASE_URL + "/auth/create-instructor"
  }

  // PROFILE ENDPOINTS
  export const profileEndpoints = {
    GET_USER_DETAILS_API: BASE_URL + "/profile/getUserDetails",
    GET_USER_ENROLLED_COURSES_API: BASE_URL + "/profile/getEnrolledCourses",
    GET_INSTRUCTOR_DATA_API: BASE_URL + "/profile/instructorDashboard",
    GET_ALL_STUDENT : BASE_URL + "/profile/getallstudentusers",
    GET_ALL_INSTRUCTUR : BASE_URL + "/profile/getallinstructorusers",
    GET_ADMIN_DASHBOARD_DATA : BASE_URL + "/profile/adminDashboard",
    DELETE_USER_API: BASE_URL + "/profile/deleteProfile",
  }

  // STUDENTS ENDPOINTS
  export const studentEndpoints = {
    COURSE_PAYMENT_API: BASE_URL + "/payment/capturePayment",
    COURSE_VERIFY_API: BASE_URL + "/payment/verifyPayment",
    SEND_PAYMENT_SUCCESS_EMAIL_API: BASE_URL + "/payment/sendPaymentSuccessEmail",
  }

  // COURSE ENDPOINTS
  export const courseEndpoints = {
    GET_ALL_COURSE_API: BASE_URL + "/course/getAllCourses",
    COURSE_DETAILS_API: BASE_URL + "/course/getCourseDetails",
    EDIT_COURSE_API: BASE_URL + "/course/editCourse",
    COURSE_CATEGORIES_API: BASE_URL + "/course/showAllCategories",
    CREATE_COURSE_API: BASE_URL + "/course/createCourse",
    CREATE_SECTION_API: BASE_URL + "/course/addSection",
    CREATE_SUBSECTION_API: BASE_URL + "/course/addSubSection",
    UPDATE_SECTION_API: BASE_URL + "/course/updateSection",
    UPDATE_SUBSECTION_API: BASE_URL + "/course/updateSubSection",
    GET_ALL_INSTRUCTOR_COURSES_API: BASE_URL + "/course/getInstructorCourses",
    DELETE_SECTION_API: BASE_URL + "/course/deleteSection",
    DELETE_SUBSECTION_API: BASE_URL + "/course/deleteSubSection",
    DELETE_COURSE_API: BASE_URL + "/course/deleteCourse",
    GET_FULL_COURSE_DETAILS_AUTHENTICATED:
      BASE_URL + "/course/getFullCourseDetails",
    LECTURE_COMPLETION_API: BASE_URL + "/course/updateCourseProgress",
    CREATE_RATING_API: BASE_URL + "/course/createRating",
  }

  // RATINGS AND REVIEWS
  export const ratingsEndpoints = {
    REVIEWS_DETAILS_API: BASE_URL + "/course/getReviews",
  }

  // CATAGORIES API
  export const categories = {
    CATEGORIES_API: BASE_URL + "/course/showAllCategories",
    CREATE_CATEGORY_API: BASE_URL + "/course/createCategory",
    DELETE_CATEGORY_API: BASE_URL + "/course/deleteCategory",
    }

  // CATALOG PAGE DATA
  export const catalogData = {
    CATALOGPAGEDATA_API: BASE_URL + "/course/getCategoryPageDetails",
  }
  // CONTACT-US API
  export const contactusEndpoint = {
    CONTACT_US_API: BASE_URL + "/reach/contact",
  }

  // SETTINGS PAGE API
  export const settingsEndpoints = {
    UPDATE_DISPLAY_PICTURE_API: BASE_URL + "/profile/updateDisplayPicture",
    UPDATE_PROFILE_API: BASE_URL + "/profile/updateProfile",
    CHANGE_PASSWORD_API: BASE_URL + "/auth/changepassword",
    DELETE_PROFILE_API: BASE_URL + "/profile/deleteProfile",
  }


  //razorpay payment data and transaction id use
  export const transactionEndpoints = {
    GET_TRANSACTION_DETAILS_API: BASE_URL + "/payment/getTransactionDetails",
  };

  export const blogEndpoints = {
    CREATE_BLOG_API: BASE_URL + "/blog/create",          
    GET_ALL_BLOGS_API: BASE_URL + "/blog/all",           
    GET_SINGLE_BLOG_API: BASE_URL + "/blog",           
    UPDATE_BLOG_API: BASE_URL + "/blog",           
    DELETE_BLOG_API: BASE_URL + "/blog",             
    GET_LATEST_BLOGS_API: BASE_URL + "/blog/latest", 
    GET_TRENDING_BLOGS_API: BASE_URL + "/blog/trending",
    INCREMENT_BLOG_VIEWS_API: BASE_URL + "/blog/view",  
  };


  export const eventEndpoints = {
    CREATE_EVENT_API: BASE_URL + "/event/create",        
    GET_EVENT_BY_ID_API: BASE_URL + "/event",            
    UPDATE_EVENT_API: BASE_URL + "/event",              
    DELETE_EVENT_API: BASE_URL + "/event",  
    GET_ALL_EVENTS_API: BASE_URL + "/event",             
  };
  
  export const internEndpoints = {
    CREATE_INTERN_PROJECT_API: BASE_URL + "/intern/createintern",
    GET_INTERN_PROJECT_BY_ID_API: BASE_URL + "/intern/intern-projects",
    UPDATE_INTERN_PROJECT_API: BASE_URL + "/intern/intern-projects", 
    DELETE_INTERN_PROJECT_API: BASE_URL + "/intern/intern-projects",  
    GET_ALL_INTERN_PROJECTS_API: BASE_URL + "/intern/intern-projects", 
};

export const internSubmitEndpoints = {
  SUBMIT_FORM_API: BASE_URL + "/submit/form",
  DELETE_FORM_API: BASE_URL + "/submit/form/:id",
  SHOW_FORM_API: BASE_URL + "/submit/form/all",
 CERTIFICATE_API: BASE_URL + "/submit/certificate/:id",
}