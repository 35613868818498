import React, { useState, useEffect } from 'react';

const LeaveProject = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // Get user projects from local storage
    const storedProjects = JSON.parse(localStorage.getItem('userProjects')) || [];
    setProjects(storedProjects);
  }, []);

  const handleLeaveProject = (id) => {
    // Filter out the project user wants to leave
    const updatedProjects = projects.filter((project) => project._id !== id);
    setProjects(updatedProjects);
    localStorage.setItem('userProjects', JSON.stringify(updatedProjects)); // Update local storage
  };

  return (
    <div className="container mx-auto p-4 bg-richblack-800">
    <div className='border-b border-white'>
    <h1 className="text-3xl font-extrabold text-center mb-8 text-richblack-5">Leave a Project</h1>
    </div>
      
      {projects.length === 0 ? (
        <p className="text-center text-richblack-500 dark:text-richblack-300">No projects available to leave.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-10">
          {projects.map((project) => (
            <div
              key={project._id}
              className="bg-richblack-800  border border-richblack-300 dark:border-richblack-700 rounded-lg overflow-hidden shadow-lg transform transition hover:scale-105 hover:shadow-2xl"
            >
              <img
                src={project.image}
                alt={project.title}
                className="h-[15rem] w-full object-cover transition duration-300 hover:opacity-90"
              />
              <div className="p-4">
                <h2 className="text-lg font-semibold text-white dark:text-white mb-2">{project.title}</h2>
                <p className="text-sm text-richblack-200 dark:text-richblack-400 mb-4">{project.description}</p>

                <div className="flex items-center justify-between mt-4">
                  <button
                    onClick={() => handleLeaveProject(project._id)}
                    className="bg-[#B61A18] hover:border  hover:border-[#B61A18] hover:bg-white hover:text-[#B61A18] hover:bg-red-700 text-white text-sm font-semibold py-2 px-4 rounded transition-colors"
                  >
                    Leave Project
                  </button>
                  <span className="text-richblack-100 dark:text-richblack-100 text-sm">{project.tech}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LeaveProject;
