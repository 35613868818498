import React, { useState, useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';

const ProjectDashboard = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // Get user projects from local storage
    const storedProjects = JSON.parse(localStorage.getItem('userProjects')) || [];
    setProjects(storedProjects);
  }, []);

  const handleDelete = (id) => {
    const updatedProjects = projects.filter(project => project._id !== id);
    setProjects(updatedProjects);
    localStorage.setItem('userProjects', JSON.stringify(updatedProjects)); // Update local storage
  };

  return (
    <div className="container mx-auto p-4 bg-richblack-800 text-white">
      <div className='flex flex-row justify-between mx-4 border-b border-white'>
      <h1 className="text-3xl font-bold mb-6">Your Projects</h1>
      <Link to="/intern/projectboard">
        <button className='border border-[#CD1412] w-[10rem] rounded-xl hover:bg-[#CD1412] h-10'>
          Start New Project
        </button>
      </Link>
      </div>
      {projects.length === 0 ? (
        <p>No projects found.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-10">
          {projects.map((project) => (
            <motion.div
              key={project._id}
              className="bg-richblack-700 border border-richblack-600 rounded-lg p-6 shadow-lg transition-transform transform hover:scale-105"
              initial={{ opacity: 0, y: 10 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 10 }}
              transition={{ duration: 0.3 }}
            >
              <h2 className="text-lg font-semibold">{project.title}</h2>
              <img src={project.image} alt={project.title} className="h-[15rem] w-full object-cover rounded mb-2" />
              <div className='flex flex-row justify-between mx-2 mb-2'>
                <p className="text-sm">{project.tech}</p>
                <p className="text-sm">{project.topic}</p>
              </div>
              <p className="mb-4 text-richblack-300">{project.description}</p>
              <button
                onClick={() => handleDelete(project._id)}
                className="bg-red-600 hover:bg-red-700 text-white border border-[#CD1412] hover:bg-[#CD1412] px-4 py-2 rounded transition-colors"
              >
                Delete
              </button>
            </motion.div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProjectDashboard;
