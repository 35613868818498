import { useEffect, useState, useRef } from "react";
import {
  AiOutlineMenu,
  AiOutlineShoppingCart,
  AiOutlineClose,
} from "react-icons/ai";
import { BsChevronDown } from "react-icons/bs";
import { useSelector } from "react-redux";
import { motion } from "framer-motion";
import { Link, matchPath, useLocation } from "react-router-dom";

import logo from "../../assets/Logo/Logo-Full-Light.png";
import { NavbarLinks } from "../../data/navbar-links";
import { apiConnector } from "../../services/apiConnector";
import { categories } from "../../services/apis";
import { ACCOUNT_TYPE } from "../../ultilites/constants";
import ProfileDropdown from "../core/Auth/ProfileDropDown";

function Navbar() {
  const { token } = useSelector((state) => state.auth);
  const { user } = useSelector((state) => state.profile);
  const { totalItems } = useSelector((state) => state.cart);
  const location = useLocation();

  const [subLinks, setSubLinks] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isCatalogOpen, setIsCatalogOpen] = useState(false);

  const catalogRef = useRef(null);
  const mobileMenuRef = useRef(null);

  useEffect(() => {
    (async () => {
      setLoading(true);
      try {
        const res = await apiConnector("GET", categories.CATEGORIES_API);
        setSubLinks(res.data.data);
      } catch (error) {
        console.log("Could not fetch Categories.", error);
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        catalogRef.current &&
        !catalogRef.current.contains(event.target) &&
        mobileMenuRef.current &&
        !mobileMenuRef.current.contains(event.target)
      ) {
        setIsCatalogOpen(false);
        setIsMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const matchRoute = (route) => {
    return matchPath({ path: route }, location.pathname);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleCatalogHover = () => {
    setIsCatalogOpen(true);
  };

  const handleCatalogLeave = () => {
    setIsCatalogOpen(false);
  };

  return (
    <div
      className={`flex h-16 items-center justify-center border-b-[1px] border-b-richblack-50 bg-white dark:bg-[#121212] ${
        location.pathname !== "/" ? "bg-white" : ""
      } transition-all duration-200`}
    >
      <div className="flex lg:w-11/12 w-full max-w-maxContent items-center justify-between">
        {/* Logo */}
        <Link to="/">
          <motion.img
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 1 }}
            src={logo}
            alt="Logo"
            width={160}
            loading="lazy"
            className="h-[80px]"
          />
        </Link>

        {/* Navigation links */}
        <nav className="hidden lg:block dark:text-white">
          <ul className="flex gap-x-6 text-richblack-500 dark:text-white">
            {NavbarLinks.map((link, index) => (
              <li key={index}>
                {link.title === "Catalog" ? (
                  <div
                    onMouseEnter={handleCatalogHover}
                    onMouseLeave={handleCatalogLeave}
                    className={`group relative flex cursor-pointer items-center gap-1${
                      matchRoute("/catalog/:catalogName")
                        ? "text-[#CD1412]"
                        : "text-richblack-600 dark:text-white"
                    }`}
                  >
                    <p>{link.title}</p>
                    <BsChevronDown />
                    {isCatalogOpen && (
                      <div
                        ref={catalogRef}
                        className="absolute left-[50%] top-[-50%] z-[1000] flex w-[200px] translate-x-[-50%] translate-y-[3em] flex-col rounded-lg bg-white shadow-[0px_1px_20px_-3px_#a0aec0] p-4 text-richblack-900 opacity-100 transition-all duration-150 lg:w-[300px]"
                      >
                        <div className="absolute left-[50%] top-0 -z-10 h-7 w-10 translate-x-[0%] translate-y-[-30%] rotate-45 select-none rounded bg-white"></div>
                        {loading ? (
                          <p className="text-center">Loading...</p>
                        ) : subLinks.length ? (
                          subLinks
                            ?.filter((subLink) => subLink?.courses?.length > 0)
                            ?.map((subLink, i) => (
                              <Link
                                to={`/catalog/${subLink.name
                                  .split(" ")
                                  .join("-")
                                  .toLowerCase()}`}
                                className="rounded-lg bg-transparent py-4 pl-4 hover:bg-[#CD1412] text-richblack-600 hover:text-white"
                                key={i}
                              >
                                {subLink.name}
                              </Link>
                            ))
                        ) : (
                          <p className="text-center">No Courses Found</p>
                        )}
                      </div>
                    )}
                  </div>
                ) : (
                  <Link to={link?.path}>
                    <p
                      className={`${
                        matchRoute(link?.path)
                          ? "text-[#CD1412]"
                          : "text-richblack-600 dark:text-richblack-5"
                      }`}
                    >
                      {link.title}
                    </p>
                  </Link>
                )}
              </li>
            ))}
          </ul>
        </nav>

        {/* Mobile & Tablet Menu Icon */}
        <button
          onClick={toggleMenu}
          className="mr-4 lg:hidden  bg-[#C91513] text-richblack-5 w-10 h-10 flex items-center justify-center rounded-full"
        >
          {isMenuOpen ? (
            <AiOutlineClose fontSize={24} fill="#AFB2BF" />
          ) : (
            <AiOutlineMenu fontSize={24} fill="#AFB2BF" />
          )}
        </button>

        {/* Mobile and Tablet Dropdown Menu */}
        {isMenuOpen && (
          <div
            ref={mobileMenuRef}
            className="absolute top-14 right-0 z-50 w-[60%] md:w-[50%] md:rounded-l-xl bg-richblack-700 text-richblack-5 shadow-lg transition-transform duration-300 lg:hidden"
          >
            <ul className="flex flex-col gap-y-4 p-4">
              {/* Display Login/Signup or Profile at the top of the dropdown */}
              <div className="flex md:flex-row gap-y-2 gap-x-2 mb-4 items-center justify-center">
                {token === null ? (
                  <>
                    <Link to="/login">
                      <button className="rounded-[8px] border border-richblack-500 bg-richblack-800 px-[12px] py-[8px] text-richblack-5 w-full">
                        Log in
                      </button>
                    </Link>
                    <Link to="/signup">
                      <button className="rounded-[8px] border border-richblack-500 bg-richblack-800 px-[12px] py-[8px] text-richblack-5 w-full">
                        Sign up
                      </button>
                    </Link>
                  </>
                ) : (
                  <ProfileDropdown />
                )}
              </div>

              {NavbarLinks.map((link, index) => (
                <li key={index}>
                  {link.title === "Catalog" ? (
                    <div>
                      <div
                        onMouseEnter={handleCatalogHover}
                        onMouseLeave={handleCatalogLeave}
                        className="flex items-center gap-1 text-richblack-50 cursor-pointer"
                      >
                        <p>{link.title}</p>
                        <BsChevronDown />
                      </div>
                      {isCatalogOpen && (
                        <div
                          ref={catalogRef}
                          className="flex flex-col mt-2 pl-4"
                        >
                          {loading ? (
                            <p className="text-center">Loading...</p>
                          ) : subLinks.length ? (
                            subLinks
                              ?.filter((subLink) => subLink?.courses?.length > 0)
                              ?.map((subLink, i) => (
                                <Link
                                  to={`/catalog/${subLink.name
                                    .split(" ")
                                    .join("-")
                                    .toLowerCase()}`}
                                  className="py-2 hover:text-[#CD1412]"
                                  key={i}
                                >
                                  {subLink.name}
                                </Link>
                              ))
                          ) : (
                            <p className="text-center">No Courses Found</p>
                          )}
                        </div>
                      )}
                    </div>
                  ) : (
                    <Link to={link?.path}>
                      <p className="hover:text-[#CD1412]">{link.title}</p>
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        )}


            {/* Login / Signup / Dashboard for desktop view */}
            <div className="hidden lg:flex items-center gap-x-4 ">
          {user &&
            user?.accountType !== ACCOUNT_TYPE.INSTRUCTOR &&
            user?.accountType !== ACCOUNT_TYPE.ADMIN && (
              <Link to="/dashboard/cart" className="relative">
                <AiOutlineShoppingCart className="text-2xl text-richblack-200 dark:text-white" />
                {totalItems > 0 && (
                  <span className="absolute -bottom-2 -right-2 grid h-5 w-5 place-items-center overflow-hidden rounded-full bg-[#CD1412] text-center text-xs font-bold text-white">
                    {totalItems}
                  </span>
                )}
              </Link>
            )}
          {token === null && (
            <>
              <Link to="/login">
                <button className="rounded-[8px] border border-richblack-700 bg-richblack-800 px-[12px] py-[8px] text-richblack-100">
                  Log in
                </button>
              </Link>
              <Link to="/signup">
                <button className="rounded-[8px] border border-richblack-700 bg-richblack-800 px-[12px] py-[8px] text-richblack-100">
                  Sign up
                </button>
              </Link>
            </>
          )}
          {token !== null && <ProfileDropdown />}
        </div>
      </div>
    </div>
  );
}

export default Navbar;
