import React from 'react';
import { FaEdit, FaTrashAlt } from 'react-icons/fa';

const EventTable = ({ events, onEdit, onDelete }) => {

  if (!events || !events.length) {
    return <p>No events available.</p>;
  }
  const truncateDescription = (description, wordLimit) => {
    if (!description) return '';
    const words = description.replace(/<[^>]+>/g, '').split(' '); // Remove HTML tags
    if (words.length <= wordLimit) return description;
    return `${words.slice(0, wordLimit).join(' ')}...`;
};

  return (
    <div className="overflow-x-auto bg-white mt-7 rounded-xl"> {/* Make the table horizontally scrollable on small screens */}
      <table className="min-w-full divide-y divide-richblack-200">
        <thead className="bg-richblack-50">
          <tr>
            <th className="px-2 py-2 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Heading</th>
            <th className="px-2 py-2 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Description</th>
            <th className="px-2 py-2 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Start Date</th>
            <th className="px-2 py-2 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">End Date</th>
            <th className="px-2 py-2 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Image</th>
            <th className="px-2 py-2 md:px-6 md:py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Actions</th>
          </tr>
        </thead>
        <tbody className="divide-y divide-richblack-200 bg-richblack-100">
          {events.map((event) => (
            <tr key={event._id} className="border border-richblack-200">
              <td className="px-2 py-4 md:px-6 md:py-4 whitespace-nowrap w-20 h-10 md:w-auto">
                {event.heading}
              </td>
              <td className="px-2 py-4 md:px-6 md:py-4 whitespace-nowrap w-20 h-10 md:w-auto"> 
                {truncateDescription(event.description, 10)}
              </td>

              <td className="px-2 py-4 md:px-6 md:py-4 whitespace-nowrap w-20 h-10 md:w-auto">
                {new Date(event.startDate).toLocaleDateString()}
              </td>
              <td className="px-2 py-4 md:px-6 md:py-4 whitespace-nowrap w-20 h-10 md:w-auto">
                {new Date(event.endDate).toLocaleDateString()}
              </td>
              <td className="px-2 py-4 md:px-6 md:py-4 whitespace-nowrap w-20 h-20 md:w-auto">
                {event.thumbnailUrl ? (
                  <img src={event.thumbnailUrl} alt={event.heading} className="w-full h-full object-cover rounded" />
                ) : (
                  'No Image'
                )}
              </td>
              <td className="px-2 py-4 md:px-6 md:py-4 whitespace-nowrap w-20 h-20 md:w-auto md:h-auto">
                <button
                  onClick={() => onEdit(event._id)}
                  className="text-indigo-600 hover:text-blue-600 mx-2"
                >
                  <FaEdit className="w-5 h-5" />
                </button>
                <button
                  onClick={() => onDelete(event._id)}
                  className="text-red-600 hover:text-[#CD1412] mx-2"
                >
                  <FaTrashAlt className="w-5 h-5" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EventTable;
