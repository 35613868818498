import { apiConnector } from '../apiConnector';
import { internEndpoints } from '../apis'; 

// Create a new Intern Project
export const createInternProject = async (formData) => {
  try {
    const response = await apiConnector(
      'POST',
      internEndpoints.CREATE_INTERN_PROJECT_API,
      formData, 
      {}, // custom headers if any, otherwise leave empty
    );
    return response.data;
  } catch (error) {
    console.error("Error creating intern project:", error.response?.data?.message || error.message);
    throw error.response?.data || { message: "Something went wrong" };
  }
};

// Get all Intern Projects
export const getAllInternProjects = async () => {
  try {
    const response = await apiConnector('GET', internEndpoints.GET_ALL_INTERN_PROJECTS_API);
    return response.data;
  } catch (error) {
    console.error("Error fetching intern projects:", error.response?.data?.message || error.message);
    throw error.response?.data || { message: "Something went wrong" };
  }
};

// Get a specific Intern Project by ID
export const getInternProjectById = async (projectId) => {
  try {
    const response = await apiConnector(
      'GET', 
      `${internEndpoints.GET_INTERN_PROJECT_BY_ID_API}/${projectId}`
    );
    return response.data;
  } catch (error) {
    console.error("Error fetching intern project:", error.response?.data?.message || error.message);
    throw error.response?.data || { message: "Something went wrong" };
  }
};

// Update an Intern Project by ID
export const updateInternProject = async (projectId, updatedData) => {
  try {
    const response = await apiConnector(
      'PUT', 
      `${internEndpoints.UPDATE_INTERN_PROJECT_API}/${projectId}`, 
      updatedData,
    );
    return response.data;
  } catch (error) {
    console.error("Error updating intern project:", error.response?.data?.message || error.message);
    throw error.response?.data || { message: "Something went wrong" };
  }
};

// Delete an Intern Project by ID
export const deleteInternProject = async (projectId) => {
  try {
    const response = await apiConnector(
      'DELETE', 
      `${internEndpoints.DELETE_INTERN_PROJECT_API}/${projectId}`,
    );
    return response.data;
  } catch (error) {
    console.error("Error deleting intern project:", error.response?.data?.message || error.message);
    throw error.response?.data || { message: "Something went wrong" };
  }
};
