import React, { useState } from 'react';
import { createInternProject } from '../../../../services/operations/InternData';
import { toast } from 'react-hot-toast';
import { motion } from 'framer-motion';
import TableIntern from './TableIntern';

const CreateInternForm = () => {
  const [formData, setFormData] = useState({
    title: '',
    description: '',
    tech: '',
    topic: '',
    image: null,
  });

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setFormData({ ...formData, image: e.target.files[0] });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const projectData = new FormData();
    projectData.append('title', formData.title);
    projectData.append('description', formData.description);
    projectData.append('tech', formData.tech);
    projectData.append('topic', formData.topic);
    if (formData.image) projectData.append('image', formData.image);

    try {
      await createInternProject(projectData);
      toast.success('Intern project created successfully!');
    } catch (error) {
      toast.error('Failed to create project');
    }
  };

  return (
    <div className="min-h-screen  flex flex-col gap-10 items-center justify-center p-4">
      <motion.form 
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
        className="dark:bg-white bg-richblack-700 rounded-lg shadow-lg p-8 max-w-lg w-full"
        onSubmit={handleSubmit}
      >
        <motion.h2 
          initial={{ scale: 0.8, opacity: 0 }}
          animate={{ scale: 1, opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.1 }}
          className="text-3xl font-bold mb-6 text-center text-white dark:text-richblack-800"
        >
          Create Intern Project
        </motion.h2>

        <motion.div
          whileFocus={{ scale: 1.05, transition: { duration: 0.3 } }}
          className="mb-4"
        >
          <input 
            type="text" 
            name="title" 
            placeholder="Project Title" 
            onChange={handleInputChange} 
            className="w-full px-4 py-2 text-richblack-700 bg-richblack-50 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 transition-all duration-300"
          />
        </motion.div>

        <motion.div
          whileFocus={{ scale: 1.05, transition: { duration: 0.3 } }}
          className="mb-4"
        >
          <textarea 
            name="description" 
            placeholder="Project Description" 
            onChange={handleInputChange}
            className="w-full px-4 py-2 text-richblack-700 bg-richblack-50 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 transition-all duration-300"
          />
        </motion.div>

        <motion.div 
          className="mb-4"
        >
          <select 
            name="tech" 
            onChange={handleInputChange}
            className="w-full px-4 py-2 text-richblack-700 bg-richblack-50 rounded-md focus:outline-none focus:ring-2 focus:ring-purple-600 transition-all duration-300"
          >
            <option value="">Select Technology</option>
            <option value="React">React</option>
            <option value="Java">Java</option>
            <option value="Nextjs">Nextjs</option>
            <option value="C++">C++</option>
            <option value="C">C</option>
            <option value="Python">Python</option>
            <option value="Ruby">Ruby</option>
          </select>
        </motion.div>

        <motion.div 
          className="mb-4"
        >
          <select 
            name="topic" 
            onChange={handleInputChange}
            className="w-full px-4 py-2 text-richblack-700 bg-richblack-50 rounded-md focus:outline-none focus:ring-2 focus:ring-[#a715fc] transition-all duration-300"
          >
            <option value="">Select Topic</option>
            <option value="MERN Stack Project">MERN Stack Project</option>
            <option value="MERN Stack Project">Artifical Intelligence</option>
            <option value="MERN Stack Project">Machine Learning</option>
            <option value="MERN Stack Project">Graphic Design</option>
            <option value="MERN Stack Project">UI/UX</option>
          </select>
        </motion.div>

        <motion.div 
          className="mb-4"
        >
          <input 
            type="file" 
            name="image" 
            onChange={handleFileChange}
            className="w-full text-richblack-700 bg-richblack-50 rounded-md focus:outline-none focus:ring-2 focus:ring-[#a715fc] transition-all duration-300"
          />
        </motion.div>

        <motion.div
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          transition={{ duration: 0.3 }}
        >
          <button 
            type="submit" 
            className="w-full bg-[#c91515] hover:border-2 hover:border-[#c91515] hover:bg-transparent hover:text-black text-white font-semibold py-2 rounded-md hover:bg-gradient-to-l transition-all duration-300 focus:outline-none focus:ring-4 focus:ring-[#a715fc]"
          >
            Create Project
          </button>
        </motion.div>
      </motion.form>
      <div>
        <TableIntern/>
      </div>
    </div>
  );
};

export default CreateInternForm;
