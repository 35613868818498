import React, { useEffect, useMemo, useState } from "react";
import Particles, { initParticlesEngine } from "@tsparticles/react";
import { loadSlim } from "@tsparticles/slim";

const ParticlesComponent = ({ darkMode }) => {
  const [init, setInit] = useState(false);

  useEffect(() => {
    initParticlesEngine(async (engine) => {
      await loadSlim(engine);
    }).then(() => {
      setInit(true);
    });
  }, []);

  const options = useMemo(
    () => ({
      background: {
        color: {
          value: darkMode
            ? "#141414" 
            : "transparent", 
        },
      },
      fpsLimit: 120,
      interactivity: {
        events: {
          onClick: {
            enable: true,
            mode: "repulse",
          },
          onHover: {
            enable: true,
            mode: "grab",
          },
        },
        modes: {
          push: {
            distance: 200,
            duration: 15,
          },
          grab: {
            distance: 150,
          },
        },
      },
      particles: {
        color: {
          value: darkMode ? ["#00FFCB", "#FF007F", "#0072ff"] : "#000814", // AI-themed colors in dark mode
        },
        links: {
          color: darkMode ? "#00FFCB" : "#ffffff",
          distance: 150,
          enable: darkMode, 
          opacity: darkMode ? 0.5 : 0, // Links visible only in dark mode
          width: 1.5,
        },
        move: {
          direction: "none",
          enable: true,
          outModes: {
            default: "bounce",
          },
          random: true,
          speed: darkMode ? 1.5 : 1, // Faster particles in dark mode
          straight: false,
        },
        number: {
          density: {
            enable: true,
          },
          value: 150,
        },
        opacity: {
          value: darkMode ? 0.9 : 0.0, // Visible only in dark mode
        },
        shape: {
          type: "circle",
        },
        size: {
          value: { min: 1, max: 3 },
        },
      },
      detectRetina: true,
    }),
    [darkMode]
  );

  return <Particles id="tsparticles" init={init} options={options} />;
};

export default ParticlesComponent;
