import React from 'react'
import HighlightText from '../HomePage/HighlightText'

const Quote = () => {
  return (
    <div className=" text-xl md:text-4xl font-semibold mx-auto py-5 pb-20 text-center text-richblack-700 dark:text-white">
        <span className="bg-gradient-to-b dak:from-[#E65C00] dark:to-[#F9D423] from-[#CD1412] to-[#CD1412] text-transparent bg-clip-text font-bold">Revolutionizing the way we learn.</span> Our
        innovative platform combines technology {" "}
        <span >
            {" "}
            expertise
        </span>
        , and community to create an {" "}
        <span className="bg-gradient-to-b dark:from-[#E65C00] dark:to-[#F9D423] from-[#CD1412] to-[#CD1412] text-transparent bg-clip-text font-bold">
           
            {"  "}unparalleled E-Learning
            experience.
        </span> 
    </div>
  )
}

export default Quote