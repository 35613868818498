import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux'; // Import useDispatch
import { sidebarData } from '../../../../../data/intern';
import { logout } from '../../../../../slices/authSlice'; // Import logout action

const Intersidbar = () => {
  const [activeTab, setActiveTab] = useState('');
  const [showLogoutPopup, setShowLogoutPopup] = useState(false); // For logout confirmation popup
  const navigate = useNavigate();
  const dispatch = useDispatch(); // Initialize dispatch

  const handleTabClick = (tab) => {
    setActiveTab(tab.name);
    navigate(tab.link);
  };

  const handleLogout = () => {
    // Dispatch the logout action
    dispatch(logout());
    // Navigate to the login page after logout
    navigate('/login');
  };

  return (
    <div className="h-screen pt-[5.5rem] bg-[#121212] dark:bg-white dark:text-[#121212] text-white p-4 flex flex-col ">
      <div>
        {sidebarData.map((section, index) => (
          <div key={index} className="mb-6">
            <h2 className="font-bold mb-2">{section.title}</h2>
            <ul>
              {section.tabs.map((tab, idx) => (
                <li
                  key={idx}
                  className={`flex items-center cursor-pointer py-2 px-4 rounded ${
                    activeTab === tab.name ? 'bg-richblack-600 dark:text-white' : 'hover:bg-richblack-700 dark:hover:text-white'
                  }`}
                  onClick={() => handleTabClick(tab)}
                >
                  {/* Icon Render */}
                  {tab.icon && <tab.icon className="mr-2 text-lg" />}
                  {tab.name}
                </li>
              ))}
            </ul>
          </div>
        ))}
      </div>

      {/* Logout Button */}
      <div className="">
        <button
          onClick={() => setShowLogoutPopup(true)}
          className="w-full py-2 bg-[#CD1412] hover:bg-[#991614] text-white rounded"
        >
          Logout
        </button>
      </div>

      {/* Logout Confirmation Popup */}
      {showLogoutPopup && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
          <div className="bg-white rounded-lg p-6 w-80 shadow-lg text-center">
            <h2 className="text-xl font-bold mb-4 text-richblack-700">Are you sure you want to log out?</h2>
            <div className="flex justify-around">
              <button
                onClick={() => setShowLogoutPopup(false)}
                className="bg-richblack-400 hover:bg-richblack-500 text-white py-2 px-4 rounded"
              >
                Cancel
              </button>
              <button
                onClick={handleLogout}
                className="bg-[#CD1412] hover:bg-[#CD1412] text-white py-2 px-4 rounded"
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Intersidbar;
