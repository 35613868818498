import { VscDashboard, VscProject, VscSignOut } from 'react-icons/vsc';

export const sidebarData = [
  {
    title: "Menu",
    tabs: [
      { name: "Dashboard", link: "/intern/interndashboard", icon: VscDashboard },
     
      { name: "Project Board", link: "/intern/projectboard", icon: VscProject }
    ]
  },
  {
    title: "Project Actions",
    tabs: [
      { name: "Submit Project", link: `/intern/submitproject`, icon: VscProject },
      { name: "Certificate", link: "#", icon: VscProject },
      { name: "Leave Project", link: "/intern/leaveproject", icon: VscProject }
    ]
  },
];
