import img1 from "./img/8.jpg";
import img2 from "./img/3.jpg";
import img3 from "./img/6.jpg";
import img4 from "./img/4.jpg";
import img5 from "./img/7.jpg";
import img6 from "./img/2.jpg";


// data.js
export const teacherData = [
    {
      image: img3,
      name: 'MR Arman ',
      description: 'Expert Full Stack Developer specializing in JavaScript, React, Node.js, hands-on projects, clear lessons, and real-world applications.',
      courseName: ' Full Stack Course',
      link: 'https://www.linkedin.com/in/gulshan-yadav-30a980175/',
    },

    {
        image: img1,
        name: 'Mr. Awadhesh',
        description: "Awadhesh Kumar, with a Master's in Mathematics from IIT Roorkee and a Bachelor's from BHU, excels in AI & Mathematical research .",
        courseName: 'Artificial Intelligence',
        link: 'https://www.linkedin.com/in/awadhesh-kumar-4a3b63244?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app ',
      },
      {
        image: img4,
        name: 'Mr. Sumit',
        description: 'Sumit excels in AI, Python, and data science, offering clear, practical lessons with hands-on projects and real-world applications.',
        courseName: 'Machine Learning',
        link: 'https://www.linkedin.com/in/gulshan-yadav-30a980175/',
      },
      {
        image: img6,
        name: 'Mr. Yasar Beg',
        description: 'Mr. Yasar Beg holds a Master’s in Physics and an M.Tech in Data Science, blending expertise in physics and ML & DL',
        courseName: 'Machine Learning',
        link: 'https://www.linkedin.com/in/gulshan-yadav-30a980175/',
      },
    
  ];
  