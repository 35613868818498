import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getAllEvents } from '../services/operations/evnts';

const EventsPage = () => {
  const [events, setEvents] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        const data = await getAllEvents();
        // console.log("Fetched Events Data:", data);
        if (data && Array.isArray(data.events)) {
          setEvents(data.events);
        } else {
          console.warn("Expected events array but got:", data);
          setEvents([]);
        }
      } catch (error) {
        console.error("Failed to fetch events:", error.message);
      }
    };

    fetchEvents();
  }, []);

  const handleCardClick = (eventId) => {
    navigate(`/events/${eventId}`);
  };

  return (
    <div>
      <div className='flex items-center justify-center text-center mt-10'>
        <h3 className='w-full bg-gradient-to-br from-[#833AB4] via-[#FD1D1D] to-[#FCB045] bg-clip-text text-3xl font-semibold text-transparent'>Join Us for a Range of Engaging and Enriching Experiences</h3>
      </div>
      <div className="p-4 flex items-center justify-center mt-8 mb-4">
        {events.length > 0 ? (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-1 gap-4 lg:w-[50%]">
            {events.map((event, index) => (
              <Link to={`/events/${event._id}`} key={event.id || index}>
                <div

                  onClick={() => handleCardClick(event.id)}
                  className="cursor-pointer bg-richblack-600 text-white dark:bg-white dark:text-richblack-700 shadow-lg rounded-lg overflow-hidden transition-transform transform hover:scale-105  lg:h-[30rem]"
                >
                  <img
                    src={event.thumbnailUrl}
                    alt={event.heading}
                    className="w-full h-[20rem] object-cover"
                  />
                  <div className="p-4">
                    <h3 className="text-2xl font-bold text-white dark:text-gray-100">
                      {event.heading}
                    </h3>
                    <p className="text-gray-600 mt-4">
                      {`${event.description.split(' ').slice(0, 25).join(' ')}${event.description.split(' ').length > 20 ? '...' : ''}`}
                    </p>

                    <div className="flex flex-row justify-between text-gray-400 dark:text-gray-500 text-sm mt-2">
                      <p>{`Start Date: ${new Date(event.startDate).toLocaleDateString()}`}</p>
                      <p>{`End Date: ${new Date(event.endDate).toLocaleDateString()}`}</p>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        ) : (
          <p className="text-center text-gray-500 dark:text-gray-400">No events available</p>
        )}
      </div>
    </div>

  );
};

export default EventsPage;
