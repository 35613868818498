import React from 'react'
import HighlightText from './HighlightText'
import know_your_progress from "../../../assets/Images/Untitled-3.png";
import compare_with_others from "../../../assets/Images/Untitled-2.png";
import plan_your_lessions from "../../../assets/Images/Untitled-1.png"
import CTAButton from "../HomePage/Button"

const LearingLanguageSection = () => {
  return (
    <div className='mt-[100px] mb-16'>
        <div className='w-11/12 flex flex-col gap-5 text-center items-center'>

                    <div className='text-4xl font-semibold texi-center text-black dark:text-white '>
                    Your swiss knife for
                    <HighlightText text={"learning any language"} />
                    </div>

                    <div className='text-center text-[#2C333F] dark:text-richblack-50 mx-auto text-base font-semibold w-[73%]'>
                    Using spin making learning multiple languages easy. with 20+ languages realistic voice-over, progress tracking, custom schedule and more.
                    </div>

                        {/* 3 images created for div */}
                    <div className='flex lg:flex-row items-center justify-center mt-1  lg:ml-0 ml-7'>
                        <img src={know_your_progress} alt="know_your_progress" 
                            className='object-contain lg:-mr-32 -mr-[50px] lg:w-[450px] lg:h-[450px] w-[200px] h-[200px] rounded-2xl '
                        />

                        <img src={compare_with_others} alt="know_your_progress" 
                            className='object-contain lg:-mr-[0px] lg:w-[450px] lg:h-[450px] w-[200px] h-[200px]'
                        />

                        <img src={plan_your_lessions} alt="know_your_progress" 
                            className='object-contain lg:-ml-36 -ml-[60px] lg:w-[450px] lg:h-[450px] w-[200px] h-[200px]'
                        />


                    </div>

                    <div className='w-fit'>
                        <CTAButton active={true} linkto={"blogs"}> 
                        Learn More 
                        </CTAButton>
                    </div>

        </div>
    </div>
  )
}

export default LearingLanguageSection