import React from "react"
import * as Icon1 from "react-icons/bi"
import * as Icon3 from "react-icons/hi2"
import * as Icon2 from "react-icons/io5"

const stepContactList = [
  {
    icon: "HiUserCircle",
    heading: "Become an Instructor",
    description: "Apply to become an instructor with us.",
    
  },
  {
    icon: "HiSquare3Stack3D",
    heading: "Course Details",
    description: "Get detailed information about our courses.",
    
  },
  {
    icon: "IoLocationOutline",
    heading: "give us full Address",
    description: "In form give us full address your ",
    
  },
  {
    icon: "HiCurrencyRupee",
    heading: "Buy Process",
    description: "Assistance with the buying process.",
    
  },
  {
    icon: "HiUserGroup",
    heading: "Women Entrepreneur Discount",
    description: "Inquire about discounts for women entrepreneurs  Enquery.",
    
  },
]

const SteptoContact = () => {
  return (
    <div className="flex flex-col gap-6 mt-5 rounded-xl bg-white text-[#CD1412] p-4 lg:p-6  border border-[#CD1412]">
      {stepContactList.map((ele, i) => {
        let Icon = Icon1[ele.icon] || Icon2[ele.icon] || Icon3[ele.icon]
        return (
          <div className="flex flex-col gap-[2px] p-3 text-sm text-[#CD1412]" key={i}>
            <div className="flex flex-row items-center gap-3">
              <Icon size={25} />
              <h1 className="text-lg font-semibold">{ele.heading}</h1>
            </div>
            <p className="font-medium">{ele.description}</p>
            
          </div>
        )
      })}
    </div>
  )
}

export default SteptoContact

