// src/reducer/rootReducer.js
import { combineReducers } from "@reduxjs/toolkit";
import authReducer from "../slices/authSlice";
import cartReducer from "../slices/cartSlice";
import courseReducer from "../slices/courseSlice";
import profileReducer from "../slices/ProfileSlice";
import viewCourseReducer from "../slices/viewCourseSlice";
import categoryReducer from "../slices/categorySlice";
import projectReducer from "../slices/projectSlice"; 

const rootReducer = combineReducers({
  auth: authReducer,
  profile: profileReducer,
  course: courseReducer,
  cart: cartReducer,
  viewCourse: viewCourseReducer,
  category: categoryReducer,
  projects: projectReducer, // Add project reducer here
});

export default rootReducer;
