import { internSubmitEndpoints } from "../apis";
import { apiConnector } from "../apiConnector";

// Function to submit the intern form
export const submitInternForm = async (formData, token) => {
  try {
    const response = await apiConnector(
      "POST",
      internSubmitEndpoints.SUBMIT_FORM_API,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data", 
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (response.data.success) {
      // Handle successful form submission
      console.log(response.data.message);
      return response.data.data; // Return the submitted data for further use if needed
    } else {
      throw new Error(response.data.message); // Throw an error for unsuccessful submission
    }
  } catch (error) {
    console.error("Error submitting intern form:", error.message);
    throw error; // Rethrow the error for handling in the calling component
  }
};
// Function to delete the intern form by ID
export const deleteInternForm = async (id) => {
  try {
    const response = await apiConnector(
      "DELETE",
      internSubmitEndpoints.DELETE_FORM_API.replace(":id", id)
    );
    if (response.data.success) {
      // Handle successful deletion
      console.log(response.data.message);
    }
  } catch (error) {
    console.error("Error deleting intern form:", error);
  }
};

// Function to fetch all intern forms
export const showAllInternForms = async () => {
  try {
    const response = await apiConnector(
      "GET",
      internSubmitEndpoints.SHOW_FORM_API
    );
    if (response.data.success) {
      return response.data.data; // Return the array of intern forms
    }
  } catch (error) {
    console.error("Error fetching intern forms:", error);
    throw error; // Rethrow the error to be handled in the component
  }
};

export const fetchCertificateByUserId = async (id) => {
  console.log(
    `[fetchCertificateByUserId] Fetching certificate for user ID: ${id}`
  );

  try {
    const response = await apiConnector(
      "GET",
      internSubmitEndpoints.CERTIFICATE_API.replace(":id", id),
      { responseType: "blob" }
    );

    console.log(response, "respobnseeeeeeee")

    console.log(
      `[fetchCertificateByUserId] Response status code: ${response.status}`
    );

    if (response.status === 200) {
      console.log(
        `[fetchCertificateByUserId] Successfully fetched certificate for user ID: ${id}`
      );
      return response.data;
    }
  } catch (error) {
    console.error(
      `[fetchCertificateByUserId] Error fetching certificate for user ID: ${id}`,
      error
    );
    throw error;
  }
};

