import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import { getAllBlogs, getLatestBlogs, getTrendingBlogs } from '../services/operations/blogapi'; // Import your API functions
import Footer from '../components/common/Footer';

SwiperCore.use([Navigation]);

const Blogs = () => {
    const [blogs, setBlogs] = useState([]);
    const [latestBlogs, setLatestBlogs] = useState([]);
    const [trendingBlogs, setTrendingBlogs] = useState([]);

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                // Fetch all blogs
                const allBlogs = await getAllBlogs();
                setBlogs(allBlogs);

                // Fetch latest blogs
                const latest = await getLatestBlogs();
                setLatestBlogs(latest);

                // Fetch trending blogs
                const trending = await getTrendingBlogs();
                setTrendingBlogs(trending);

            } catch (error) {
                console.error("Failed to fetch blogs:", error);
            }
        };
        fetchBlogs();
    }, []);



    const truncateDescription = (description, wordLimit) => {
        if (!description) return '';
        const words = description.replace(/<[^>]+>/g, '').split(' '); // Remove HTML tags
        if (words.length <= wordLimit) return description;
        return `${words.slice(0, wordLimit).join(' ')}...`;
    };

    // Function to render blog sections with a no blogs message
    const renderBlogSection = (title, blogList) => {
        return (
            <div>
                <h2 className="text-2xl font-semibold mb-4 text-richblack-600 dark:text-white">{title}</h2>
                {blogList.length > 0 ? (
                    <Swiper
                        spaceBetween={20}
                        navigation
                        pagination={false} // Disable pagination dots
                        breakpoints={{
                            640: {
                                slidesPerView: 1, // 1 slide on small screens (e.g., mobile)
                            },
                            768: {
                                slidesPerView: 2, // 2 slides on medium screens (e.g., tablets)
                            },
                            1024: {
                                slidesPerView: 3, // 3 slides on large screens (e.g., desktop)
                            },
                        }}
                    >
                        {blogList.map((blog) => (
                            <SwiperSlide key={blog._id}>
                                <Link to={`/blogs/${blog._id}`}>
                                    <div className="bg-white dark:bg-gray-800 p-4 rounded-lg shadow-lg hover:shadow-2xl">
                                        <img src={blog.imageUrl} alt={blog.heading} className="w-full h-48 object-cover rounded-lg" />
                                        <h3 className="text-lg font-semibold mt-4 text-gray-800 dark:text-gray-200">{blog.heading}</h3>
                                        <p className="text-gray-600 dark:text-gray-400 mt-2">   {truncateDescription(blog.description, 10)}...</p>
                                    </div>
                                </Link>
                            </SwiperSlide>
                        ))}
                    </Swiper>
                ) : (
                    <p className="text-richblack-400 dark:text-richblack-25">No blogs created in this category yet.</p>
                )}
            </div>
        );
    };

    return (
        <div className="container mx-auto p-8">
            <h1 className="text-4xl font-bold mb-8 text-richblack-600 dark:text-white">Blogs</h1>

            {renderBlogSection('Latest Blogs', latestBlogs)}
            {renderBlogSection('Trending Blogs', trendingBlogs)}

            <div className="mb-16">
                <h2 className="text-2xl font-semibold mb-4 text-richblack-700 dark:text-richblack-5">All Blogs</h2>
                {blogs.length > 0 ? (
                    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
                        {blogs.map((blog) => (
                            <Link to={`/blogs/${blog._id}`} key={blog._id}>
                                <div className="bg-richblack-50 dark:bg-gray-800 p-4 rounded-lg shadow-lg hover:shadow-2xl">
                                    <img src={blog.imageUrl} alt={blog.heading} className="w-full h-48 object-cover rounded-lg" />
                                    <h3 className="text-lg font-semibold mt-4 text-gray-800 dark:text-gray-200">{blog.heading}</h3>
                                    <p className="text-gray-600 dark:text-gray-400 mt-2"> {truncateDescription(blog.description, 10)}</p>
                                </div>
                            </Link>
                        ))}
                    </div>
                ) : (
                    <p className="text-gray-600 dark:text-gray-400">No blogs created yet.</p>
                )}
            </div>
            <div>
            <Footer/>
            </div>
            
        </div>
    );
};

export default Blogs;
