import React from 'react';
import { Link } from 'react-router-dom';

const Button = ({ children, active, linkto }) => {
  return (
    <Link to={linkto} className="relative group">
      <div
        className={`relative text-center text-[14px] px-6 py-3 rounded-md font-bold
          ${active ? "bg-[#CD1412] text-white" : "bg-richblack-800 dark:bg-richblack-200 dark:text-richblack-700"}
          overflow-hidden transition-transform duration-300 ease-in-out
          hover:scale-95 hover:shadow-[0px_0px_20px_5px_#929090]`}
      >
        {children}
        {/* Shine Effect */}
        <div
          className="absolute inset-0 bg-gradient-to-r from-transparent via-shine-light dark:via-shine-light to-transparent opacity-100
            transform -translate-x-full group-hover:animate-shine"
        ></div>
      </div>
    </Link>
  );
}

export default Button;
