import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";
import { submitInternForm } from "../../../../../../services/operations/InternSubmit";

const InternFormComponent = ({ projectId }) => {
  const { id } = useParams();
  console.log(id, "Id in InternFormComponent");

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    state: "",
    district: "",
    githubLink: "",
    yourReview: "",
    message: "",
    projectId: projectId, // Ensure projectId is included here
  });

  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    console.log(projectId, "projectId in InternFormComponent");
  }, [projectId]);

  // Form validation rules (part of form logic)
  const validateForm = () => {
    let validationErrors = {};

    if (!formData.name) validationErrors.name = "Name is required";
    if (!formData.email) validationErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      validationErrors.email = "Email is invalid";
    if (!formData.state) validationErrors.state = "State is required";
    if (!formData.district) validationErrors.district = "District is required";
    if (
      formData.githubLink &&
      !/^https?:\/\/[^\s$.?#].[^\s]*$/.test(formData.githubLink)
    )
      validationErrors.githubLink = "GitHub link must be a valid URL";
    if (formData.yourReview.length < 20)
      validationErrors.yourReview =
        "Your review must be at least 20 characters";
    if (formData.message.length > 1000)
      validationErrors.message = "Message cannot exceed 1000 characters";
    if (!formData.message) validationErrors.message = "Message is required";
    if (formData.state === formData.district)
      validationErrors.state = "State and District cannot be the same";

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Form submission started");
    console.log("Form data:", formData);
    if (!validateForm()) {
      console.log("Validation failed");
      console.log("Errors:", errors);
      return;
    }

    const dataToSend = new FormData();
    Object.entries(formData).forEach(([key, value]) => {
      dataToSend.append(key, value);
    });

    console.log("Data to send:", Object.fromEntries(dataToSend));
    const result = await submitInternForm(dataToSend);
    if (result) {
      const userId = result.userId;
      console.log("Form submitted successfully!");
      console.log("Result:", result);
      setFormData({
        name: "",
        email: "",
        state: "",
        district: "",
        githubLink: "",
        yourReview: "",
        message: "",
        projectId: projectId, // reset the projectId as well
      });
      console.log("Navigating to certificate page");
      navigate(`/intern/certificate`, {
        state: {
          name: formData.name,
          project: formData.yourReview, // Assuming you meant yourReview instead of description
          userId: result.userId,
        },
      });
    }
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="max-w-7xl mx-auto mt-10 p-6"
    >
      <div className="grid grid-cols-1 lg:grid-cols-5 gap-6">
        {/* General Application Rules - 40% Width */}
        <div className="lg:col-span-2 bg-richblack-700 dark:bg-white p-6 rounded-lg shadow-md h-[500px] text-white dark:text-richblack-800 overflow-auto">
          <h2 className="text-xl font-bold text-richblack-5 dark:text-richblack-700 mb-4">
            Application Rules
          </h2>
          <ul className="list-disc list-inside space-y-2">
            <li>Provide accurate information in the form.</li>
            <li>Ensure the GitHub link is valid and public.</li>
            <li>Form submission is limited to once per user.</li>
            <li>Follow all instructions carefully.</li>
            <li>Incomplete applications will not be reviewed.</li>
            <li>Ensure that your email is valid for communication.</li>
            <li>Do not upload malicious files.</li>
            <li>Be patient for a response after submission.</li>
          </ul>
        </div>

        {/* Form Section - 60% Width */}
        <div className="lg:col-span-3 p-6 rounded-lg shadow-md bg-richblack-700 dark:bg-white text-white dark:text-richblack-900">
          <h2 className="text-xl font-bold mb-4">Submit Your Application</h2>
          <form onSubmit={handleSubmit}>
            {/* Name */}
            <div className="flex flex-row gap-4">
              <div className="mb-4">
                <label className="block text-richblack-5 dark:text-richblack-700">
                  Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  placeholder="Enter your name"
                  className={`w-full border ${
                    errors.name ? "border-[#CB1412]" : "border-richblack-300"
                  } p-2 rounded-lg  lg:w-[17rem]`}
                />
                {errors.name && (
                  <p className="text-[#CB1412] text-sm">{errors.name}</p>
                )}
              </div>

              {/* Email */}
              <div className="mb-4">
                <label className="block text-richblack-5 dark:text-richblack-700">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  className={`w-full border ${
                    errors.email ? "border-[#CB1412]" : "border-richblack-300"
                  } p-2 rounded-lg  lg:w-[17rem]`}
                />
                {errors.email && (
                  <p className="text-[#CB1412] text-sm">{errors.email}</p>
                )}
              </div>
            </div>

            {/* State and District */}
            <div className="grid grid-cols-2 gap-4">
              {/* State */}
              <div className="mb-4">
                <label className="block text-richblack-5 dark:text-richblack-700">
                  State
                </label>
                <input
                  type="text"
                  name="state"
                  value={formData.state}
                  onChange={handleChange}
                  placeholder="Enter your state"
                  className={`w-full border ${
                    errors.state ? "border-[#CB1412]" : "border-richblack-300"
                  } p-2 rounded-lg`}
                />
                {errors.state && (
                  <p className="text-[#CB1412] text-sm">{errors.state}</p>
                )}
              </div>

              {/* District */}
              <div className="mb-4">
                <label className="block text-richblack-5 dark:text-richblack-700">
                  District
                </label>
                <input
                  type="text"
                  name="district"
                  value={formData.district}
                  onChange={handleChange}
                  placeholder="Enter your district"
                  className={`w-full border ${
                    errors.district
                      ? "border-[#CB1412]"
                      : "border-richblack-300"
                  } p-2 rounded-lg`}
                />
                {errors.district && (
                  <p className="text-[#CB1412] text-sm">{errors.district}</p>
                )}
              </div>
            </div>
            {/* GitHub Link */}
            <div className="mb-4 w-full">
              <label
                className="block text-sm font-medium text-richblack-5 dark:text-richblack-700"
                htmlFor="githubLink"
              >
                GitHub Link
              </label>
              <input
                type="url"
                name="githubLink"
                value={formData.githubLink}
                onChange={handleChange}
                placeholder="Enter your GitHub link"
                className={`w-full border ${
                  errors.githubLink ? "border-[#CB1412]" : "border-richblack-300"
                } p-2 rounded-lg`}
              />
              {errors.githubLink && (
                <p className="text-[#CB1412] text-sm">{errors.githubLink}</p>
              )}
            </div>

            {/* Review */}
            <div className="mb-4">
              <label
                className="block text-sm font-medium text-richblack-5 dark:text-richblack-700"
                htmlFor="yourReview"
              >
                Your Review
              </label>
              <textarea
                name="yourReview"
                value={formData.yourReview}
                onChange={handleChange}
                placeholder="Write your review (at least 20 characters)"
                className={`w-full border ${
                  errors.yourReview ? "border-[#CB1412]" : "border-richblack-300"
                } p-2 rounded-lg`}
                rows="4"
              />
              {errors.yourReview && (
                <p className="text-[#CB1412] text-sm">{errors.yourReview}</p>
              )}
            </div>

            {/* Message */}
            <div className="mb-4">
              <label
                className="block text-sm font-medium text-richblack-5 dark:text-richblack-700"
                htmlFor="message"
              >
                Message
              </label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                placeholder="Enter your message (max 1000 characters)"
                className={`w-full border ${
                  errors.message ? "border-[#CB1412]" : "border-richblack-300"
                } p-2 rounded-lg`}
                rows="4"
              />
              {errors.message && (
                <p className="text-[#CB1412] text-sm">{errors.message}</p>
              )}
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
    </motion.div>
  );
};

export default InternFormComponent;
