import React from 'react';
import Intersidbar from '../components/core/Dashboard/intership/Interndashboad/Intersidbar';
import { Outlet } from 'react-router-dom';

const InternDashboad = () => {
  return (
    <div className="flex h-screen">
     
      <div className="w-[20%]">
        <Intersidbar />
      </div>
    
      <div className="w-[80%] flex-1 mt-6 overflow-auto">
        <div className="mx-auto w-full  py-10">
          
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default InternDashboad;
