import React from "react";
import Footer from "../components/common/Footer";

const Terms = () => {
  return (
    <div className="min-h-screen flex flex-col bg-gray-100">
    <div className="flex-grow flex flex-col items-center justify-center p-6">
      <div className="bg-white shadow-2xl rounded-xl max-w-4xl w-full p-8 md:p-12">
      <h1 className="text-4xl font-extrabold mb-4 text-[#CD1412]">
            Terms and Conditions
          </h1>

        <p className="text-lg leading-relaxed text-gray-700 mb-6">
          Welcome to IDC India! By using our website and services, you agree to
          comply with our terms and conditions. These terms govern your use of
          our platform, including course enrollments, payment terms,
          intellectual property rights, and conduct guidelines.
        </p>

        <p className="text-lg leading-relaxed text-gray-700 mb-6">
          You agree not to misuse our services or infringe on the rights of
          others. We reserve the right to update these terms at any time, and
          continued use of our platform signifies your acceptance of any changes.
        </p>

        <p className="text-lg leading-relaxed text-gray-700 mb-6">
          Please review our complete Terms and Conditions for further details. If you
          have any questions or concerns, feel free to contact us at any time.
        </p>
      </div>
    </div>
    <Footer />
  </div>
  );
};

export default Terms;
