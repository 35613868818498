import { ACCOUNT_TYPE } from "../ultilites/constants";
export const sidebarLinks = [
  {
    id: 1,
    name: "My Profile",
    path: "/dashboard/my-profile",
    icon: "VscAccount",
  },
  {
    id: 2,
    name: "Dashboard",
    path: "/dashboard/instructor",
    type: ACCOUNT_TYPE.INSTRUCTOR,
    icon: "VscDashboard",
  },

  {
    id: 3,
    name: "My Courses",
    path: "/dashboard/my-courses",
    type: ACCOUNT_TYPE.INSTRUCTOR,
    icon: "VscVm",
  },

  {
    id: 4,
    name: "Add Course",
    path: "/dashboard/add-course",
    type: ACCOUNT_TYPE.INSTRUCTOR,
    icon: "VscAdd",
  },
  {
    id: 5,
    name: "Enrolled Courses",
    path: "/dashboard/enrolled-courses",
    type: ACCOUNT_TYPE.STUDENT,
    icon: "VscMortarBoard",
  },
  {
    id: 14,
    name: "Internship",
    path: "/dashboard/internshipportal",
    type: ACCOUNT_TYPE.STUDENT,
    icon: "VscLightbulbAutofix",
  },
  {
    id: 6,
    name: "Your Cart",
    path: "/dashboard/cart",
    type: ACCOUNT_TYPE.STUDENT,
    icon: "VscHistory",
  },
  {
    id: 7,
    name: "Dashboard",
    path: "/dashboard/admin",
    type: ACCOUNT_TYPE.ADMIN,
    icon: "VscDashboard",
  },
  {
    id: 8,
    name: "Courses",
    path: "/dashboard/admin-courses",
    type: ACCOUNT_TYPE.ADMIN,
    icon: "VscVm",
  },


  {
    id: 9,
    name: "Users",
    path: "/dashboard/users-admin",
    type: ACCOUNT_TYPE.ADMIN,
    icon: "VscMortarBoard",
  },
  
  {
    id: 10,
    name: "Events-Create",
    path: "/eventcreate",
    type: ACCOUNT_TYPE.ADMIN,
    icon: "VscVm",
  },
  {
    id: 11,
    name: "Create Account",
    path: "SignupAdmin",
    type: ACCOUNT_TYPE.ADMIN,
    icon: "VscVm",
  },
  {
    id: 12,
    name: "Create-Category",
    path: "/CreateCategory",
    type: ACCOUNT_TYPE.ADMIN,
    icon: "VscVm",
  },
  {
    id: 13,
    name: "Create-Blog",
    path: "/CreateBlog",
    type: ACCOUNT_TYPE.ADMIN,
    icon: "VscVm",
  },
  {
    id: 16,
    name: "Inter-Project List",
    path: "/interprojectlist",
    type: ACCOUNT_TYPE.ADMIN,
    icon: "VscVm",
  },
  {
    id: 15,
    name: "Create-intern Project",
    path: "/adminintern",
    type: ACCOUNT_TYPE.ADMIN,
    icon: "VscVm",
  },
];
