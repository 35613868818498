import React, { useState } from 'react';
import { motion, useSpring } from 'framer-motion';
import interImage from '../../../../assets/Images/intern.png';
import robot from '../../../../assets/Images/robot.png';
import MarqueeRew from './ReviewMar';
import { Link } from 'react-router-dom';

const InterHome = () => {
  const [mouseX, setMouseX] = useState(0);
  const [mouseY, setMouseY] = useState(0);

  // Spring values for smooth transitions
  const smoothX = useSpring(mouseX, { stiffness: 300, damping: 20 });
  const smoothY = useSpring(mouseY, { stiffness: 300, damping: 20 });

  // Handle mouse movement
  const handleMouseMove = (e) => {
    const x = (e.clientX / window.innerWidth) * 10;
    const y = (e.clientY / window.innerHeight) * 10;
    setMouseX(x);
    setMouseY(y);
  };

  return (
    <div onMouseMove={handleMouseMove}>
      <div className='flex flex-col'>
        {/* Main Section */}
        <section className="flex flex-col items-center justify-center mt-5 text-richblack-700 dark:text-white">
          <p className="text-2xl text-[#CD1412] mb-2">Jump Start Your Growth</p>
          <h1 className="text-3xl md:text-5xl font-bold text-center leading-tight mb-4">
            Want to hone your skills and gain experience?
          </h1>
          <p className="text-center text-base md:text-lg text-gray-400 mb-6 max-w-lg mx-auto">
            A platform where you will explore, experiment, learn, participate, and
            build a project based on an industry-defined approach.
          </p>
          <Link to="/intern/interndashboard">
          <button className="bg-[#CD1412] hover:bg-[#971513] text-white font-medium py-2 px-6 rounded-full">
            Get Started
          </button>
          </Link>
        
        </section>

        {/* Dynamic Image Section with Advanced Framer Motion Animation */}
        <motion.div
          className="relative w-full h-[32rem]  bg-no-repeat bg-cover bg-center"
          style={{
            backgroundImage: `url(${interImage})`,

          }}
        >
        </motion.div>

        <div className=" text-richblack-700 dark:text-white py-16 px-8 flex justify-between items-center min-h-screen">
          {/* Left Section */}
          <div className="max-w-md">
            <p className="text-[#CD1412] text-lg">Get industry ready</p>
            <h1 className="text-4xl font-bold mt-4">
              Projects across multiple technologies with experience letter
            </h1>
            <p className="text-gray-400 mt-4">
              More than 500+ real time industry projects across all major domains. Get a real experience letter post project completion.
            </p>
            <div className='mt-10'>
              <button class="relative inline-flex items-center justify-center px-6 py-3 overflow-hidden text-white transition duration-300 bg-[#fc211e] border rounded-lg group hover:bg-transparent">
                <span class="absolute inset-0 w-full h-full transition-transform duration-300 transform -translate-x-full bg-[#b91f1c] rounded-lg group-hover:translate-x-0"></span>
                <span class="relative z-10">Explore More</span>
              </button>
            </div>
          </div>

          {/* Right Section */}
          <div className='mr-10'>
            <img src={robot} alt="" className='w-[20rem] h-[24rem]' />
          </div>
        </div>

        <div>
          <MarqueeRew/>
        </div>
      </div>
    </div>
  );
};

export default InterHome;
