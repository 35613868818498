import React from "react";
import ContactUsForm from "../../ContactPage/ContactUsForm";

const ContactFormSection = () => {
  return (
    <div className="mx-auto shadow-[0px_0px_30px_-10px_#1a202c]  rounded-3xl bg-white
    lg:w-[50%] w-[100%]">
      <h1 className="text-center text-4xl font-bold text-[#CD1412] mt-5">Get in Touch</h1>
      <p className="text-center text-richblack-500 mt-3">
        We&apos;d love to here for you, Please fill out this form.
      </p>
      <div className="mt-5 mx-auto lg:w-[100%] p-10 ">
        <ContactUsForm />
      </div>
    </div>
  );
};

export default ContactFormSection;