import React from "react";
import { HiUsers } from "react-icons/hi";
import { ImTree } from "react-icons/im";

const CourseCard = ({ cardData, currentCard, setCurrentCard }) => {
  return (
    <div
      className={`relative w-[360px] lg:w-[30%] ${
        currentCard === cardData?.heading
          ? "bg-white shadow-[12px_12px_0_0] border shadow-[#7B4BEB]"
          : "bg-richblack-800"
      } text-richblack-25 h-[300px] box-border cursor-pointer rounded-2xl overflow-hidden group`}
      onClick={() => setCurrentCard(cardData?.heading)}
    >
      {/* Background Image */}
      <div
        className="absolute inset-0 bg-cover bg-center transition-transform duration-500 group-hover:scale-110"
        style={{ backgroundImage: `url(${cardData.imageUrl})` }}
      ></div>

      {/* Overlay */}
      <div
        className="absolute inset-0 bg-black transition-opacity duration-300 opacity-0 group-hover:opacity-40"
      ></div>

      {/* Sliding Animation */}
      <div
        className="absolute inset-0 bg-richblack-800 transition-transform duration-500 transform -translate-y-full group-hover:translate-y-0 opacity-70"
      ></div>

      {/* Content */}
      <div className="absolute inset-0 flex flex-col justify-between my-8 items-center text-center text-[#CD1412] opacity-0 transition-opacity duration-500 group-hover:opacity-100">
        <div className="font-semibold text-[20px]">{cardData.heading}</div>
        <div className="text-richblack-5 mt-2">{cardData.description}</div>
        <div className="flex justify-between w-full px-6 py-3 font-medium">
          {/* Level */}
          <div className="flex items-center gap-2 text-[16px] text-[#5e34f5]">
            <HiUsers />
            <p>{cardData.level}</p>
          </div>

          {/* Flow Chart */}
          <div className="flex items-center gap-2 text-[16px] text-[#754FFE]">
            <ImTree />
            <p>{cardData.lessionNumber} Lessons</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
