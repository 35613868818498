import React, { useEffect, useState } from 'react';
import { showAllInternForms, deleteInternForm } from '../../../../../services/operations/InternSubmit'; // Update the path if necessary
import { motion } from 'framer-motion';
import { toast } from 'react-toastify'; // Import react-toastify for notifications

const InternFormsCard = () => {
  const [internForms, setInternForms] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const fetchInternForms = async () => {
      try {
        const forms = await showAllInternForms();
        setInternForms(forms);
      } catch (err) {
        setError('Failed to load intern forms');
      } finally {
        setLoading(false);
      }
    };

    fetchInternForms();
  }, []);

  // Function to handle deletion of an intern form
  const handleDelete = async (id) => {
    try {
      await deleteInternForm(id);
      setInternForms((prevForms) => prevForms.filter(form => form._id !== id)); // Remove from state
      toast.success('Intern form deleted successfully!'); // Show success toast
    } catch (err) {
      toast.error('Failed to delete intern form.'); // Show error toast
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <div>
      <div className='flex items-center justify-center underline text-[#CD1412]'>
        <h1 className="text-2xl font-bold text-[#CD1412]">Intern Forms</h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 p-6">

        {internForms.map((form) => (
          <motion.div
            key={form._id}
            className="dark:bg-white bg-richblack-800 text-white dark:text-richblack-700 shadow-lg rounded-lg p-4 lg:h-[20rem]"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3 }}
          >
            <h3 className="text-lg font-bold text-gray-800">{form.name}</h3>
            <p className="text-gray-600">Email: {form.email}</p>
            <p className="text-gray-600">State: {form.state}</p>
            <p className="text-gray-600">District: {form.district}</p>
            <p className="text-gray-600">
              GitHub Link: <a href={form.githubLink} className="text-blue-100 hover:underline">{form.githubLink}</a>
            </p>
         
            <p className="text-gray-600">Your Review: {form.yourReview}</p>
            <p className="text-gray-600">Message: {form.message}</p>
            <button
              onClick={() => handleDelete(form._id)}
              className="mt-2 text-[#CD1412] border border-[#CD1412] w-[5rem] rounded-lg hover:bg-[#CD1412] hover:text-white hover:underline"
            >
              Delete
            </button>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default InternFormsCard;
