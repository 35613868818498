import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fetchCertificateByUserId } from '../../../../../services/operations/InternSubmit';

const CertificatePage = (props) => {
  const location = useLocation();
  const { name, project, userId: userIdFromLocation } = location.state || {};
  const userId = props.userId || userIdFromLocation;  
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [certificateUrl, setCertificateUrl] = useState(null);

  useEffect(() => {
    const fetchCertificate = async () => {
      if (!userId) {
        setError('User ID is missing.');
        setLoading(false);
        return;
      }

      setLoading(true);
      try {
        const certificateBlob = await fetchCertificateByUserId(userId);

        // Create a blob URL
        const url = URL.createObjectURL(certificateBlob);
        setCertificateUrl(url);
      } catch (error) {
        console.error('Error fetching certificate:', error);
        setError('Failed to fetch the certificate.');
      } finally {
        setLoading(false); // End loading state
      }
    };

    fetchCertificate();

    // Clean up blob URL when component unmounts
    return () => {
      if (certificateUrl) {
        URL.revokeObjectURL(certificateUrl);
      }
    };
  }, [userId, certificateUrl]); // Run effect when userId or certificateUrl changes

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  return (
    <div className="max-w-7xl mx-auto mt-10 p-6 text-center">
      <h2 className="text-2xl font-bold mb-4">Certificate of Completion</h2>
      {certificateUrl ? (
        <>
          <p className="text-lg mb-4">Congratulations, {name}!</p>
          <p className="text-md mb-4">You have successfully completed the project: {project}</p>
          <a
            href={certificateUrl}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg"
            download={`${userId}_certificate.pdf`} // Specify the filename for download
          >
            Download Your Certificate
          </a>
        </>
      ) : (
        <p className="text-lg">No certificate available.</p>
      )}
    </div>
  );
};

export default CertificatePage;
