import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom'; // We use Link for navigation

const SubmitProject = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    // Fetch user projects from localStorage (mocked)
    const storedProjects = JSON.parse(localStorage.getItem('userProjects')) || [
      { _id: '1', title: 'AI Chatbot', description: 'A chatbot powered by AI', tech: 'React, AI', image: 'ai_chatbot_image_url' },
      { _id: '2', title: 'E-commerce App', description: 'A platform for online shopping', tech: 'MERN Stack', image: 'ecommerce_image_url' },
    ];
    setProjects(storedProjects);
  }, []);

  return (
    <div className="container mx-auto p-4 bg-richblack-800">
      <h1 className="text-3xl font-extrabold text-center mb-8 text-white">Submit Your Projects</h1>
      
      {projects.length === 0 ? (
        <p className="text-center text-richblack-500 dark:text-richblack-300">No projects found to submit.</p>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-10">
          {projects.map((project) => (
            <div
              key={project._id}
              className="bg-richblack-800 border border-richblack-300 rounded-lg overflow-hidden shadow-lg transform transition hover:scale-105"
            >
              <img
                src={project.image}
                alt={project.title}
                className="h-[15rem] w-full object-cover"
              />
              <div className="p-4">
                <h2 className="text-lg font-semibold text-white mb-2">{project.title}</h2>
                <p className="text-sm text-richblack-200 mb-4">{project.description}</p>

                <div className="flex items-center justify-between mt-4">
                  <Link to={`/intern/submitproject/${project._id}`}>
                    <button
                      className="bg-[#CD1412] hover:bg-white hover:text-[#CD1412] text-white text-sm font-semibold py-2 px-4 rounded"
                    >
                      Submit Project
                    </button>
                  </Link>
                  <span className="text-richblack-100 text-sm">{project.tech}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default SubmitProject;
