import React from 'react';
import { FaRunning } from 'react-icons/fa';

const Error = () => {
  return (
    <div className="flex flex-col justify-center items-center mt-20 text-3xl text-black dark:text-white transition-colors duration-300">
      <div className="flex items-center mb-6">
        <FaRunning className="animate-running text-6xl text-pink-200 dark:text-pink-500 mr-4 transition-colors duration-300" />
        <span className="animate-bounce text-pure-greys-800 dark:text-pure-greys-300">Oops!</span>
      </div>
      <div className="flex flex-col items-center text-center">
        <p className="text-richblack-700 dark:text-richblack-300">This Page is under Process</p>
        <p className="text-richblack-700 dark:text-richblack-300">Please try Another Courses</p>
      </div>
    </div>
  );
};

export default Error;
