import img1 from "./img/image/full1.jpg";
import img2 from "./img/image/full2.jpg";
import img3 from "./img/image/full3.jpg";
import img4 from "./img/image/ai.jpg";
import img5 from "./img/image/ai1.jpg";
import img6 from "./img/image/ai2.jpg";
import img7 from "./img/image/ds1.jpg";
import img9 from "./img/image/ds2.jpg";
import img8 from "./img/image/ds3.jpg";
import img10 from "./img/image/eng1.jpg";
import img11 from "./img/image/eng2.jpg";
import img12 from "./img/image/eng3.jpg";
import img13 from "./img/image/enter.jpg";
import img14 from "./img/image/lead.jpg";
import img15 from "./img/image/inn.jpg";




export const HomePageExplore = [
    {
        tag: 'Full Stack Development',
        courses : [
            {
                heading : "Full Stack Development",
                description : "Master the art of Full Stack Development with our comprehensive course. From front-end to back-end, learn the essential skills needed to build dynamic, responsive web applications. Gain hands-on experience with the latest technologies and frameworks, and prepare yourself for a successful career in web development.",
                level : 'Beginner to Advance',
                lessionNumber : 6,
                imageUrl: img1 
            },
            {
                heading : "MERN Stack Development",
                description : "MERN Stack Development: Dive into the world of full-stack development with our comprehensive course. Learn MongoDB, Express.js, React, and Node.js to build dynamic web applications. Master backend and frontend technologies, create interactive user interfaces, and gain real-world skills for modern web development.",
                level : 'Beginner to Advance',
                lessionNumber : 6,
                imageUrl: img2, 
            },
            {
                heading : "Benifit Of Full Stack",
                description : "Unlock your full potential with our Full Stack Development course! Gain comprehensive skills in both front-end and back-end technologies, from creating dynamic user interfaces to building robust server-side applications. Master essential tools and frameworks, enhance your career opportunities, and build complete, scalable web solutions.",
                level : 'Beginner to Advance',
                lessionNumber : 6,
                imageUrl: img3
            },
        ]
    },
    {
        tag: 'Artifical Intelligence',
        courses : [
            {
                heading : "AI for Beginners: Unlock the Future",
                description : "Embark on a journey into the world of Artificial Intelligence. Discover how AI is shaping our future, learn the basics of neural networks, and explore practical applications. Ideal for beginners eager to understand AI's impact on technology and innovation.",
                level : 'Beginner to Advance',
                lessionNumber : 6,
                imageUrl: img4 
            },
            {
                heading : "Mastering AI: From Basics to Breakthroughs",
                description : "Dive deep into AI with our comprehensive course. Explore fundamental concepts, develop hands-on skills with real-world projects, and unlock the potential of AI. Perfect for those looking to transform their understanding into practical expertise and career opportunities.",
                level : 'Beginner to Advance',
                lessionNumber : 6,
                imageUrl: img5
            },
            {
                heading : "AI Innovations: Transform Your Skills",
                description : "Experience the cutting edge of AI technology. Learn about groundbreaking innovations in machine learning, data science, and automation. This course offers unique insights and hands-on training to equip you with skills to excel in the rapidly evolving field ",
                level : 'Beginner to Advance',
                lessionNumber : 6,
                imageUrl: img6 
            },
        ]
    },
    {
        tag: 'Data Science',
        courses : [
            {
                heading : "Unlock the Secrets of Data Science",
                description : "Dive into the world of data with our comprehensive Data Science course. Learn to analyze, interpret, and visualize complex data sets using cutting-edge tools and techniques. Master predictive modeling and uncover insights that drive strategic decisions. Start your journey to becoming a data-driven professional today!",
                level : 'Beginner to Advance',
                lessionNumber : 6,
                imageUrl: img7 
            },
            {
                heading : "Transform Data into ",
                description : "Explore the secrets behind data-driven decision-making with our Data Science program. From data cleaning to advanced analytics, our course covers it all. Gain hands-on experience with real-world projects and industry-standard tools. Equip yourself with the skills needed to excel in the rapidly growing field of data science.",
                level : 'Beginner to Advance',
                lessionNumber : 6,
                imageUrl: img8 
            },
            {
                heading : "Drive Innovation with Data Science",
                description : "Elevate your career with our Data Science course designed to transform you into an expert. Learn how to leverage data to solve complex problems, create predictive models, and drive business growth. With practical exercises and expert guidance, you’ll be prepared to make impactful data-driven decisions in any industry.",
                level : 'Beginner to Advance',
                lessionNumber : 6,
                imageUrl: img9 
            },
        ]
    },
    {
        tag: 'English Communication',
        courses : [
            {
                heading : "Speak with Confidence",
                description : "Unlock the power of confident communication with our English Communication course. Develop natural conversational skills, refine your pronunciation, and learn effective speaking strategies. Whether for business or personal growth, enhance your fluency and make every conversation impactful. Enroll today to start speaking with confidence!",
                level : 'Beginner to Advance',
                lessionNumber : 6,
                imageUrl: img10 
            },
            {
                heading : "Advance Your Career Skills",
                description : "Elevate your professional presence with our tailored English Communication course. Gain expertise in business English, presentation skills, and negotiation tactics. Perfect for career advancement, this course will help you express ideas clearly and persuasively. Take the next step towards professional excellence now!",
                level : 'Beginner to Advance',
                lessionNumber : 6,
                imageUrl: img11
            },
            {
                heading : "Engage with Persuasive English",
                description : "Transform your ability to influence and engage others with our English Communication course. Learn techniques to captivate audiences, present ideas compellingly, and build meaningful connections. Perfect for leaders, sales professionals, and anyone looking to improve their communication prowess. Sign up to enhance your influence today!",
                level : 'Beginner to Advance',
                lessionNumber : 6,
                imageUrl: img12
            },
        ]
    },
    {
        tag: 'Innovation and Entrepreneurship',
        courses : [
            {
                heading : "Revolutionize with Cutting-Edge Innovation",
                description : "Dive into the world of groundbreaking ideas and disruptive technologies. Our course equips you with the tools and mindset to transform creative concepts into real-world innovations. Unlock your potential and lead the future of technology and business with unparalleled strategies and insights.",
                level : 'Beginner',
                lessionNumber : 6,
                imageUrl: img13
            },
            {
                heading : "Master the Art of Entrepreneurship",
                description : "Transform your vision into a successful venture with our comprehensive entrepreneurship course. Learn from industry experts how to build, scale, and manage a business from scratch. Gain practical knowledge on startup strategies, funding, and market dynamics to turn your entrepreneurial dreams into reality.",
                level : 'Beginner',
                lessionNumber : 6,
                imageUrl: img14
            },
            {
                heading : "Lead with Impact and Influence",
                description : "Elevate your leadership skills with our dynamic course designed for future leaders. Explore essential techniques for motivating teams, strategic decision-making, and effective communication. Develop the qualities needed to inspire and drive success in any organization, and become a leader who makes a significant impact.",
                level : 'Beginner',
                lessionNumber : 6,
                imageUrl: img15
            },
        ]
    },
]